import { enumerate, FilterPredicate, mapWhereDefined, Optional, Redefine } from '@peachy/utility-kit-pure'
import { keys, values } from 'lodash-es'
import { ClaimStage, ClaimStages, BenefitType, BenefitTypeable, AppFeature } from '../domain/types'
import { benefitTypeOf } from '../domain/utils'
import * as PlanConfigJson from '@punnet/product-pure'
import { Plan, PlanConfig } from '@punnet/product-client'
import { filter, isEmpty } from 'lodash-es'

export const Obligations = enumerate(['MANDATORY', 'ENCOURAGED', 'OPTIONAL'] as const)
export type Obligation = keyof typeof Obligations

export type BenefitLimit = Limit<'PENCE' | 'USES'>

export type BenefitUtilisationPolicy = {
    [ClaimStages.COVER_CHECK]?: {
        obligation?: Obligation
    }
    [ClaimStages.CLAIM]?: {
        obligation?: Obligation
        lodgementWindowInWeeks: number
    }
    limit?: BenefitLimit
}
type Limit<Unit extends 'PENCE' | 'USES'> = {
    value: number
    unit: Unit
}

export type CashLimited<Thing extends {limit?: Limit<any>}> = Redefine<Thing, 'limit', Readonly<Limit<'PENCE'>>>
export type UsesLimited<Thing extends {limit?: Limit<any>}> = Redefine<Thing, 'limit', Readonly<Limit<'USES'>>>
export type Limited<Thing> = CashLimited<Thing> | UsesLimited<Thing>

export type BenefitConfigUtilisationPolicy = Redefine<BenefitUtilisationPolicy, 'limit', Pick<BenefitLimit, 'unit'>>
export type BenefitConfig = {
    type: BenefitType
    displayName: string
    parentType?: BenefitType
    offerAsUpsell: boolean
    utilisationPolicy?: BenefitConfigUtilisationPolicy
    drivesFeatures?: AppFeature[]
}
type ParentBenefitConfig = {
    type: BenefitType
    displayName: string
    utilisationPolicy?: Omit<BenefitConfigUtilisationPolicy, 'limit'>
}
export type ProductsConfig = {
    benefitsByType: Record<BenefitType, BenefitConfig>
    parentBenefitsByType: Record<BenefitType, ParentBenefitConfig>
    // this is only optional because Peachy Individual config hasn't been refactored/created yet so can't be supplied in that context.  Nothing in the peachy app requires this config yet though either
    planConfig?: PlanConfigJson.Config
}

/**
 * There is a bit of a disjointed overlap (oxymoron?) in plan/product config between the mobile app (MA), the backend (BE), and the web front end WFE.
 * This service gets contructed with all these seperate bits of config and aims to pull some of it together.  It's not perfect, and still remains disjointed.
 * Main concerns: 
 *     * there is no config at all for the peachy individual product offering in the BE or WFE, it does exist for MA though.
 *     * MA config represents a two level hierarchy of benefits (see MENTAL_HEALTH) where we can hang properties at each level. BE & WEF config allows a benefit to define "sub types" but not to be able to hang properties off of them (display name etc...)
 * This service was initially created for MA config and as such most methods expose the MA config model.  Methods that expose the other config model should be commented as doing so
 */
export class ProductConfigService {

    readonly planConfig: PlanConfig

    constructor(readonly config: ProductsConfig) {
        this.planConfig = config.planConfig ? new PlanConfig(config.planConfig) : undefined
        this.warnIfNecessary()
    }

    getParentTypeOf(benefitType: BenefitType) {
        return this.config.benefitsByType[benefitType].parentType
    }

    getBenefitTypesWithAnyObligationToCoverCheck() {
        return this.getBenefitTypesWithAnyObligationTo(ClaimStages.COVER_CHECK)
    }

    getBenefitTypesWithAnyObligationToClaim() {
        return this.getBenefitTypesWithAnyObligationTo(ClaimStages.CLAIM)
    }

    getUpsellableBenefitTypes() {
        return this.getUpsellableBenefits().map(it => it.type)
    }
    
    getUpsellableBenefits() {
        return this.getAllBenefitsConfig({offerAsUpsell: true})
    }
    
    getAllBenefitsConfig(filterArgs?: FilterPredicate<BenefitConfig>) {
        const all = values(this.config.benefitsByType)
        return filterArgs ? filter(all, filterArgs) : all
    }

    getFeatureDrivingBenefits() {
        return this.getAllBenefitsConfig(it => !isEmpty(it.drivesFeatures))
    }

    getConfigForBenefit(benefitType: BenefitType) {
        return this.config.benefitsByType[benefitType]
    }

    getConfigForBenefitOrParent(benefitType: BenefitType) {
        return this.getConfigForBenefit(benefitType) ?? this.config.parentBenefitsByType[benefitType]
    }

    getClaimLodgementWindowInWeeksForBenefit(benefitType: BenefitType) {
        return this.getUtilisationPolicyFor<BenefitUtilisationPolicy['CLAIM']>(benefitType, ClaimStages.CLAIM).lodgementWindowInWeeks
    }

    shouldSubmitClaimActivityForAssessment(benefitType: BenefitType, claimStage: ClaimStage) {
        const policy = this.getUtilisationPolicyFor(benefitType, claimStage) ?? this.getParentUtilisationPolicyFor(benefitType, claimStage)
        return ([Obligations.MANDATORY, Obligations.ENCOURAGED] as string[]).includes(policy?.obligation)
    }

    getBenefitDisplayName(thing: BenefitTypeable) {
        return this.getConfigForBenefitOrParent(benefitTypeOf(thing))?.displayName
    }

    /**
     * @returns non mobile app config model plans, ie. the WFE and BE model is exposed here so be careful of expectations around benefit hierarchy properties
     */
    getPackagedPlans(predicate?: FilterPredicate<Plan>) {
        const plans = this.planConfig?.getAllPlans() ?? []
        return predicate ? filter(plans, predicate) : plans
    }

    getPackagedPlanUpgradeOptions({currentPackagedPlanId}: {currentPackagedPlanId: string}) {
        
        const allPlans = this.getPackagedPlans()
        const upsellableBenefitTypes = this.getUpsellableBenefitTypes()
        
        const plans = filterNonUpsellableBenefitsFrom(allPlans, upsellableBenefitTypes)

        const currentPackagedPlanIndex = plans.findIndex(it => it.id === currentPackagedPlanId)
        const currentPackagedPlan = plans[currentPackagedPlanIndex]
        const planUpgradeOptions = currentPackagedPlan ? plans.slice(currentPackagedPlanIndex + 1) : plans

        return {
            currentPackagedPlan,
            planUpgradeOptions
        }
    }
    
    private getBenefitTypesWithAnyObligationTo(claimStage: ClaimStage) {
        return mapWhereDefined(keys(this.config.benefitsByType), benefitType =>
            this.getUtilisationPolicyFor(benefitType, claimStage)?.obligation ? benefitType : undefined
        )
    }

    private getUtilisationPolicyFor<ForcedUtilisationPolicyType extends BenefitUtilisationPolicy[ClaimStage]>(benefitType: BenefitType, claimStage: ClaimStage) {
        return this.config.benefitsByType[benefitType]?.utilisationPolicy?.[claimStage] as Optional<ForcedUtilisationPolicyType>
    }

    private getParentUtilisationPolicyFor<ForcedUtilisationPolicyType extends BenefitUtilisationPolicy[ClaimStage]>(benefitType: BenefitType, claimStage: ClaimStage) {
        return this.config.parentBenefitsByType[benefitType]?.utilisationPolicy?.[claimStage] as Optional<ForcedUtilisationPolicyType>
    }

    private warnIfNecessary() {
        try {
            const featuresDrivenByBenefits = this.getFeatureDrivingBenefits().flatMap(it => it.drivesFeatures)
            const duplicateFeatures = featuresDrivenByBenefits.filter((feature, index) => featuresDrivenByBenefits.indexOf(feature) !== index)
            
            if (!isEmpty(duplicateFeatures)) {
                console.warn(`Features have been configured to be driven by more than one benefit which is not supported`, duplicateFeatures)
            }
        } catch (e) {
            // whatever, I was just trying to be helpful
            console.error(e)
        }
    }
}

function filterNonUpsellableBenefitsFrom(plans: Plan[], upsellableBenefitTypes: string[]) {
    return plans.map(plan => ({
        ...plan,
        benefits: plan.benefits.filter(it => upsellableBenefitTypes.includes(it.id))
    }))
}