.eBiSKG_container {
  justify-content: space-evenly;
  width: 10em;
  display: flex;
}

.eBiSKG_container > button {
  width: 70px;
}

.eBiSKG_container > button:disabled {
  opacity: .3;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7OztBQU1BOzs7O0FBSUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9wYWdlcy9BZG1pbi9zdWJzY3JpcHRpb24vQ2FuY2VsTW9kYWwvQ2FuY2VsQnV0dG9ucy5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIgIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtZXZlbmx5O1xuICAgIHdpZHRoOiAxMGVtO1xufVxuXG4uY29udGFpbmVyID4gYnV0dG9uICB7XG4gICAgd2lkdGg6IDcwcHg7XG59XG5cbi5jb250YWluZXIgPiBidXR0b246ZGlzYWJsZWQgIHtcbiAgICBvcGFjaXR5OiAwLjM7XG59XG5cbiJdLCJuYW1lcyI6W119 */