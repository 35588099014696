import * as PlanConfigJson from '@punnet/product-pure'

export type Benefit = PlanConfigJson.Benefit & PlanConfigJson.AvailableBenefit & {
    isSupported: boolean
    isActive: boolean
}

export type Excess = PlanConfigJson.Excess & PlanConfigJson.PlanExcess

export type Plan = {
    id: string
    name: string
    benefits: Benefit[]
    excess?: Excess
}