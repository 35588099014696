import {enumerate} from '@peachy/utility-kit-pure'
import {AppointmentTypeOptionId, BinaryGenderOptionId, PriceOptionId} from './enquiry/option-definitions'
import {MarketplaceResult, PracticeResultWithId} from './MarketplaceTypes'

export type WithId<T> = T & { id: string }

export const DecisionTypes = enumerate(['APPROVE', 'DECLINE'] as const)
export type DecisionType = keyof typeof DecisionTypes

export const ClaimStages = enumerate(['COVER_CHECK', 'CLAIM'] as const)
export type ClaimStage = keyof typeof ClaimStages

export type BenefitType = string
export type HasBenefitType = {type: BenefitType}
export type BenefitTypeable = BenefitType | HasBenefitType

/**
 * @deprecated renamed from BenefitType to indicate that it's Peachy specific, and deprecated it before we get round to removing all usage.  Basically any usage should be replaced with product/benefit config, or if pretty specific to a given use case then it's definitions should be moved there
 */
export const PeachyBenefitTypes = enumerate(['DENTAL' , 'OPTICAL' , 'THERAPIES' , 'MENTAL_HEALTH_IN_PATIENT' , 'MENTAL_HEALTH_OUT_PATIENT', 'CONSULTATIONS_AND_DIAGNOSTICS' , 'HOSPITAL_CARE', 'VIRTUAL_GP', 'ADVICE_AND_COUNSELLING'] as const)


export const PolicyStatuses = enumerate(['ACTIVE', 'CANCELLED'] as const)
export type PolicyStatus = keyof typeof PolicyStatuses

export const AppointmentTypes = enumerate(['VIRTUAL_GP', 'PHYSIO'] as const)
export type AppointmentType = keyof typeof AppointmentTypes

export const Genders = enumerate(['MALE', 'FEMALE', 'OTHER'] as const)
export type Gender = keyof typeof Genders

export const BinaryGenders = enumerate(['MALE', 'FEMALE'] as const)
export type BinaryGender = keyof typeof BinaryGenders

export const LifeTypes = enumerate(['PRIMARY', 'SECONDARY', 'DEPENDANT'] as const)
export type LifeType = keyof typeof LifeTypes

export type PlanYearId = string
export type BenefitAmountMap = Map<BenefitType, number>
export type PlanYearBenefitAmountMap = Map<PlanYearId, BenefitAmountMap>

export type ExcessId = string
export type ExcessAmountMap = Map<ExcessId, number>
export type PlanYearExcessAmountMap = Map<PlanYearId, ExcessAmountMap>

export const Provenances = enumerate(['USER', 'CLAIMS_ASSESSOR_REKEY'] as const)
export type Provenance = keyof typeof Provenances

export const AppFeatures = enumerate([
    'CHECK_COVER',
    'MAKE_CLAIM',
    'BOOK_VGP',
    'BOOK_PHYSIO',
    'IM1_INTEGRATION', 
    'EDIT_PLAN', 
    'CANCEL_PLAN', 
    'VIEW_PAYMENT_DETAILS',
    'ADVICE_AND_COUNSELLING',
    'DOWNLOAD_POLICY_DOC',
    'PERKS'
] as const)
export type AppFeature = keyof typeof AppFeatures

export const AppFeatureAvailabilities = enumerate(['ACTIVE', 'DISABLED', 'NOT_AVAILABLE'] as const)
export type AppFeatureAvailability = keyof typeof AppFeatureAvailabilities


export const AccountTypes = enumerate(['INDIVIDUAL', 'GROUP', 'COMPANY'] as const)
export type AccountType = keyof typeof AccountTypes

export type GeoLocation = {
    latitude: number
    longitude: number
}

export type GetCareFilters = {
    query?: string
    location?: GeoLocation
    distance?: number
    price?: PriceOptionId
    language?: any
    gender?: BinaryGenderOptionId
    appointmentType?: AppointmentTypeOptionId
    paediatric?: boolean
    viewMode: 'map' | 'list'
}

export type GetCareSearchResults = {
    practitioners: MarketplaceResult[],
    practices: PracticeResultWithId[]
}