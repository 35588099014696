import {Dictionary, IsoDatePartOnly, TemplateExecutor} from '@peachy/utility-kit-pure'

export type WithId<T> = T & { id: string }

export type ClientAttributes = Record<string, string>

export type UserIn = {
    userName: string
    password?: string
    email?: string
    clientAttributes?: ClientAttributes
    clientIdentifier?: string
}

export type UserPatchIn = WithId<Partial<UserIn>>

export type UserOut = UserIn & {
    id: string
    clientId: number,
    isEnabled: boolean,
    createdAt: Date
    optimisticLock: { value: string },
    clientAttributes?: Dictionary<any>,
    clientIdentifier?: string
}

export type UserStatus = {
    isAllowedToCreateBookings: boolean
    hasPatientRecord: boolean
    hasAcceptedTermsAndConditions: boolean
    passesClientValidation: boolean
}

export type Address = {
    addressLine1: string
    addressLine2?: string
    city: string
    postCode: string
    countryCode: string
}

export type Gender = 'Female' | 'Male' | 'Other' | 'Unknown'

export type PatientRecordIn = {
    firstName: string
    lastName: string
    dateOfBirth: IsoDatePartOnly
    email: string
    phoneNumber: string
    gender: Gender
    policyNumber?: string
    address: Address
    medications?: string[]
    medicalHistory?: string [],
    allergies?: string[],
    gp?: {
        gpName?: string,
        surgery?: {
            name?: string
            phoneNumber?: string
            email?: string
            address: Partial<Address>
        }
    },
    nominatedPharmacy?: {
        name?: string
        phoneNumber?: string
        email?: string
        address: Partial<Address>
    },
    clientIdentifier?: string
    clientAttributes?: ClientAttributes
}

export type PatientRecordOut = WithId<PatientRecordIn>

export type ConsultationType = 'Phone' | 'Video'
export type BookingIn = {
    consultationType: ConsultationType
    reasonForBooking: string
    symptoms?: string[],
    contactDetails: {
        phoneNumber: string,
        address: Address
    }
    clientIdentifier?: string
    clientAttributes?: ClientAttributes
}

export type ClinicalPractitionerOut = {
    id: string
    firstName: string
    lastName: string
    gender: Gender
    specialties: PractitionerSpecialty[]
    isRegisteredIMC: boolean
    isRegisteredGMC: boolean
    isIMC: boolean
    isGMC: boolean
}

export type ConsultationOut = {
    id: string
    status: string //should enumerate "ClinicalPractitionerWaiting"
    start: Date
    end: Date
    scheduled: {
        start: Date
        end: Date
        consultationType: ConsultationType
    },
    isPatientAllowedToJoin: boolean
    completed: boolean | undefined
}

export type BookingSessionDetails = {
    sessionType: ConsultationType
    sessionId: string
    token: string
    apiKey: string
    url?: string
    twilioToken: string
    twilioChannelName: string
    twilioServiceType: string //should think about maybe enum better representation "ProgrammableChat"
}

export type BookingOut = WithId<BookingIn> & {
    cancellationReason?: BookingCancellationReason
    cancellationReasonNotes?: string
    status: 'Cancelled' | 'PendingConsultation'
    sessionDetails: BookingSessionDetails
    caseId?: string
    caseNumber?: string
    callTicketId?: number
    createdAt: Date
    isCompleted: boolean
    optimisticLock: { value: string }
    appointments?: AppointmentOut
    clinicalPractitioners?: ClinicalPractitionerOut
    patients?: PatientRecordOut
    consultations?: ConsultationOut
}

export type BookingRelationships = 'appointment' | 'patientRecord' | 'dependant' | 'consultation' | 'appointment.clinicalPractitioner'

export type BookingCancellationReason = 'NoLongerRelevant' | 'UnableToAttend' | 'BookedInError' | 'Other'

type Base64EncodedString = string
export type FileUpload = {
    content: Base64EncodedString
    fileName: string
}

export type FileOut = {
    id: string
    fileName: string
    downloadUrl: string
    mimetype: string
    size: number
}

export type Terms = {
    id: string,
    title: string,
    body: string
    version: string
    type: 'PrivacyNotice' | 'TermsAndConditions'
    activeFrom: Date
    status?: string
}

export type PractitionerSpecialty = 'GeneralPractitioner' | 'Physiotherapist' | 'ClinicalPharmacist' | 'Counsellor'

export type Pagination = {
    number?: number,
    size?: number
}

export type FilterParams<Filters> = {
    filter?: Filters
}

export type PaginationParams = {
    page?: Pagination
}

export type IncludeParams<Relationships> = {
    include?: Relationships[]
}

export type AppointmentRelationships = 'appointmentHold' | 'clinicalPractitioner' | 'booking'

export type AppointmentStatus = 'Available' | 'OnHold' | 'NotAvailable' | 'Booked' | 'Completed'
export type AppointmentsFilter = {
    start?: Date
    end?: Date
    status?: AppointmentStatus[]
    'clinicalPractitioner.gender'?: Gender
    'clinicalPractitioner.specialties'?: PractitionerSpecialty[]
    consultationType?: ConsultationType
}

export type AppointmentOut = {
    id: string
    start: Date
    end: Date
    status: AppointmentStatus
    consultationType: ConsultationType
    hold?: {
        userId: string
    }
}

export type AppointmentHold = {
    id: string
    userId: string
    createdAt: Date
    expiresAt: Date
    clientAttributes?: ClientAttributes
    payment: {
        isRequired: boolean,
        amount?: string
        currency?: string
        paymentExchangeToken?: string
        stripePaymentClientSecret?: string
        stripePublishableKey?: string
    }
    status: string //should think about enum maybe better representation "HoldCompletedWithoutPayment"
}

export type TermsFilter = {
    status: 'Pending' | 'Accepted'
}

export type UsersFilter = {
    clientIdentifier?: string
    userName?: string
}

export type EndPoints = {
    adminUsers: string
    webhookEndpoints: TemplateExecutor
    webhookConfigurations: TemplateExecutor
    summary: string
    users: TemplateExecutor
    auth: TemplateExecutor
    terms: string
    patientRecord: TemplateExecutor
    userTerms: TemplateExecutor
    userStatus: TemplateExecutor
    dependants: TemplateExecutor
    bookings: TemplateExecutor
    bookingAttachments: TemplateExecutor
    appointments: TemplateExecutor
    appointmentHolds: TemplateExecutor
    files: TemplateExecutor
    signalR: string
    baseUrl: string
}

type BasicAuth = {
    username: string
    password: string
}

type AccessTokenAuth = {
    accessToken: string
}

type SharedSecretAuth = {
    clientId: string
    clientSecret: string
    authServerUrl: string
}

export type WebhookClientEndpointIn = {
    remoteUrl: string
    headers?: Dictionary<string>
    timeoutSeconds?: number
    isApproved?: boolean
    auth?: BasicAuth | AccessTokenAuth | SharedSecretAuth
}

export type WebhookClientEndpointOut = WithId<WebhookClientEndpointIn>

export const WebHookEvents = ['BookingCreated', 'BookingCancelled', 'ConsultationStarted', 'ConsultationCompleted', 'BookingPaymentQuery', 'BookingUpdated', 'ConsultationReminder', 'ConsultationOutputCreated', 'UserCreated', 'ConsultationIsReady'] as const
export type WebhookEvent = typeof WebHookEvents[number]


export type WebhookConfigurationIn = {
    eventType: WebhookEvent
    isEnabled: boolean
    // Automatically generated if not provided. Can be used to validate x-ms-signature header during webhook delivery
    signingSecret?: string
}

export type WebhookConfigurationOut = WithId<WebhookConfigurationIn>

