import { Dictionary } from '@peachy/utility-kit-pure'
import {CognitoMemberUserAttributes} from '@peachy/iam-pure'

type MetaData = Dictionary<any>

export interface SupportDeskService {
    initFor(user: CognitoMemberUserAttributes): Promise<boolean>
    logOut(): Promise<boolean>
    showVirtualGpHelp(): Promise<boolean>
    showNewSupportChatInput(prefillMessage?: string): Promise<boolean>
    logEvent(eventName: string, metaData ?: MetaData): void 
}