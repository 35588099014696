import {PeachyFlashRepo, PlanRepository} from '@peachy/flash-repo-peachy-client'
import {Benefit, BenefitType, DomainMappings, RepoPlan} from '@peachy/repo-domain'
import {DateInterval} from '@peachy/utility-kit-pure'
import {isBefore} from 'date-fns'
import {sortBy} from 'lodash-es'

export class BenefitsService {

    constructor(protected readonly repo: PeachyFlashRepo,
                protected readonly planRepository: PlanRepository) {
    }

    async getBenefitOfTypeForLife(type: BenefitType, lifeId: string) {
        const benefit = (await this.planRepository.findByLifeId(lifeId)).benefits.find(it => it.type === type)
        return DomainMappings.fromRepo.toBenefit(benefit)
    }

    /** 
     * @returns the most recently added benefit of each type that were "in play" at any point during the interval.  
     * Where "in play" includes cancelled benefits that are still available for claim submission (i.e. the 16 week or whatever claim window is not over) 
     * We return the "most recently added" because there may be some cancelled and then re-added benefits within the interval.  
     * For now we only care about the most recent per interval but in the future we may care about the history within the interval.  If so this logic needs to change
     */
    getBenefitsThatWereInPlayDuring(interval: DateInterval, repoPlan: RepoPlan) {
        const benefitHistory = repoPlan.benefits.map(it => DomainMappings.fromRepo.toBenefit(it))
        const benefitsActiveInInterval = benefitHistory.filter(it =>
            it.wasAcceptingClaimSubmissionsAtAnytimeDuring(interval) ||
            // this looks unnecessary, but it's not.  
            // while "wasAcceptingClaimSubmissionsAtAnytimeDuring" casts a wider net in terms of in play dates because of the claim window after cancellation.
            // "wasActiveAnytimeDuring" is still required because it casts a wider net on the types of benefits returned i.e. will include ones that don't need to be claimed against
            it.wasActiveAnytimeDuring(interval)
        )

        // get the most recently added benefit of each type (see method level comment for explanation)
        const latestActiveBenefitsInInterval = this.mostRecentlyStartedBenefitOfEachTypeFrom(benefitsActiveInInterval)
        return sortBy(latestActiveBenefitsInInterval, it => it.type)
    }

    private mostRecentlyStartedBenefitOfEachTypeFrom(benefits: Benefit[]) {
        const mostRecentlyStartedOf = (a: Benefit, b?: Benefit) => b ? isBefore(b.startDate, a.startDate) ? a : b : a

        const latestBenefitByType = benefits.reduce((collector, it) => {
            collector[it.type] = mostRecentlyStartedOf(it, collector[it.type])
            return collector
        }, {} as Record<BenefitType, Benefit>)

        return Object.values(latestBenefitByType)
    }

}
