import _ from 'lodash-es'
import {parseISO} from 'date-fns'
import {
    AppointmentHold,
    AppointmentOut,
    AppointmentRelationships,
    AppointmentsFilter,
    BookingCancellationReason,
    BookingIn,
    BookingOut,
    BookingRelationships,
    ClientAttributes,
    EndPoints,
    FileOut,
    FileUpload,
    FilterParams,
    IncludeParams,
    PaginationParams,
    PatientRecordIn,
    PatientRecordOut,
    Terms,
    TermsFilter,
    UserIn,
    UserOut,
    UserPatchIn,
    UsersFilter,
    UserStatus,
    WebhookClientEndpointIn,
    WebhookClientEndpointOut,
    WebhookConfigurationIn,
    WebhookConfigurationOut
} from './HealthHeroTypes'
import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr'
import {
    AuthDetails,
    AuthProvider,
    Dictionary,
    HttpMethod,
    NupaHttpClient,
    OptionalRequestConfig,
    template
} from '@peachy/utility-kit-pure'

export type ConsultationEventHub = {
    onConsultationEvent: (eventHandler: (event: object) => void) => void
    invokePatientConnected: <T = any>() => Promise<T>
    connection: HubConnection
}

//todo general validation max length strings, invalid chars, etc that HH accept
export class HealthHeroClient {

    private debugEnabled = false
    protected endPoints: EndPoints
    public baseUrl: string

    private adminAuth: AuthDetails | undefined = undefined
    private userAuth: Dictionary<AuthDetails> = {}

    private commonClientAttributesProvider: (method: HttpMethod) => ClientAttributes | undefined

    constructor(public readonly domain: string,
                readonly clientId: string,
                readonly authClientId: string,
                readonly clientSecret: string,
                readonly http: NupaHttpClient) {

        this.endPoints = buildEndpoints(domain, clientId)
        this.baseUrl = this.endPoints.baseUrl
        this.http.withHeaders({
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        })
    }

    withCommonClientAttributesProvidedBy(provider: (method: HttpMethod) => ClientAttributes | undefined) {
        this.commonClientAttributesProvider = provider
        return this
    }

    withDebugging(value: boolean) {
        this.debugEnabled = value
        this.http.withDebugging(value)
        return this
    }

    isDebugEnabled() {
        return this.debugEnabled
    }

    async authenticateAsUser(userId: string): Promise<AuthDetails> {
        const cached = this.userAuth[userId]
        this.userAuth[userId] = AuthDetails.ifUsable(cached) ?? await this.fetchUserAuthDetails(userId)
        return this.userAuth[userId]
    }

    async authenticateAsAdmin(): Promise<AuthDetails> {
        this.adminAuth = AuthDetails.ifUsable(this.adminAuth) ?? await this.fetchAdminAuthDetails()
        return this.adminAuth
    }

    async fetchUserAuthDetails(userId: string): Promise<AuthDetails> {
        return this.postFormDataReceiveJson(
            this.endPoints.auth(),
            `grant_type=urn:ms:oauth:sso&client_id=${this.authClientId}&client_secret=${this.clientSecret}&token_user_id=${userId}`
        ).then(mapAuthDetails)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1oauth2~1token/post
    async fetchAdminAuthDetails(): Promise<AuthDetails> {
        return this.postFormDataReceiveJson(
            this.endPoints.auth(),
            `grant_type=client_credentials&client_id=${this.authClientId}&client_secret=${this.clientSecret}&scope=MSAPI_api`
        ).then(mapAuthDetails)
    }

    async refreshUserAuthDetails(refreshToken: string): Promise<AuthDetails> {
        return this.postFormDataReceiveJson(
            this.endPoints.auth(),
            `grant_type=refresh_token&client_id=${this.authClientId}&client_secret=${this.clientSecret}&refresh_token=${refreshToken}`
        ).then(mapAuthDetails)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1restEndpoints/post
    async createWebhookEndpoint(endpoint: WebhookClientEndpointIn): Promise<WebhookClientEndpointOut> {
        const admin = await this.authenticateAsAdmin()
        const response = await this.POST(this.endPoints.webhookEndpoints(), bodyOf('restEndpoints', endpoint), admin)
        return mapEntityOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1restEndpoints/get
    async listWebhookEndpoints(): Promise<WebhookClientEndpointOut[]> {
        const admin = await this.authenticateAsAdmin()
        const response = await this.GET(this.endPoints.webhookEndpoints(), admin)
        return mapListOfEntitiesOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1restEndpoints~1%7Bid%7D/delete
    async deleteWebhookEndpoint(endpointId: string): Promise<WebhookClientEndpointOut> {
        const admin = await this.authenticateAsAdmin()
        const response = await this.DELETE(this.endPoints.webhookEndpoints({endpointId}), admin)
        return mapEntityOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1webhooks/post
    async createWebhookConfiguration(configuration: WebhookConfigurationIn, endpointId: string): Promise<WebhookConfigurationOut> {
        const admin = await this.authenticateAsAdmin()
        const relationships = {endpoint: {data: {id: endpointId}}}
        const response = await this.POST(this.endPoints.webhookConfigurations(), bodyOf('webhooks', configuration, {relationships}), admin)
        return mapEntityOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1webhooks/get
    async listWebhookConfigurations(): Promise<WebhookConfigurationOut[]> {
        const admin = await this.authenticateAsAdmin()
        const response = await this.GET(this.endPoints.webhookConfigurations(), admin)
        return mapListOfEntitiesOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1webhooks~1%7BwebhookId%7D/delete
    async deleteWebhookConfiguration(webhookId: string): Promise<WebhookConfigurationOut> {
        const admin = await this.authenticateAsAdmin()
        const response = await this.DELETE(this.endPoints.webhookConfigurations({webhookId}), admin)
        return mapEntityOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1users/post
    async createUser(user: UserIn): Promise<UserOut> {
        const response = await this.createUserRaw(user)
        return mapEntityOut(response)
    }

    async createUserRaw(user: UserIn) {
        const admin = await this.authenticateAsAdmin()
        return this.POST(this.endPoints.adminUsers, bodyOf('users', user), admin)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D/patch
    async updateUser(userId: string, user: UserPatchIn): Promise<PatientRecordOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.PATCH(this.endPoints.users({userId}), bodyOf('users', user), userAuth)
        return mapPatientRecordOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1reports~1summary/get
    async getUsageReport() {
        const admin = await this.authenticateAsAdmin()
        return this.GET(this.endPoints.summary, admin)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1admin~1clients~1%7BclientId%7D~1users/get
    async listUsers(params?: FilterParams<UsersFilter>): Promise<UserOut[]> {
        const response = await this.listUsersRaw(params)
        return mapListOfEntitiesOut(response)
    }

    async listUsersRaw(params?: FilterParams<UsersFilter>) {
        const admin = await this.authenticateAsAdmin()
        return this.GET(this.endPoints.adminUsers + withQueryParams(params), admin)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1patientRecord/post
    async createPatientRecord(userId: string, patientRecord: PatientRecordIn): Promise<PatientRecordOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.POST(this.endPoints.patientRecord({userId}), bodyOf('patients', patientRecord), userAuth)
        return mapPatientRecordOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1patientRecord/get
    async getPatientRecord(userId: string): Promise<PatientRecordOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.GET(this.endPoints.patientRecord({userId}), userAuth)
        return mapPatientRecordOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1dependents~1%7BdependentId%7D/patch
    async updatePatientRecord(userId: string, patientRecord: Partial<PatientRecordIn>): Promise<PatientRecordOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.PATCH(this.endPoints.patientRecord({userId}), bodyOf('patients', patientRecord, {id: userId}), userAuth)
        return mapPatientRecordOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1dependents/post
    async createDependant(userId: string, patientRecord: PatientRecordIn): Promise<PatientRecordOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.POST(this.endPoints.dependants({userId}), bodyOf('patients', patientRecord), userAuth)
        return mapPatientRecordOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1dependents/get
    async listDependants(userId: string): Promise<PatientRecordOut[]> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.GET(this.endPoints.dependants({userId}), userAuth)
        return mapListOfPatientRecordsOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1dependents~1%7BdependentId%7D/patch
    async updateDependant(userId: string, dependantId: string, patientRecord: Partial<PatientRecordIn>): Promise<PatientRecordOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.PATCH(this.endPoints.dependants({userId, dependantId}), bodyOf('patients', patientRecord, {id: dependantId}), userAuth)
        return mapPatientRecordOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1terms/get
    async listTerms(): Promise<Terms[]> {
        const response = await this.listTermsRaw()
        return mapListOfEntitiesOut(response, 'activeFrom')
    }

    async listTermsRaw() {
        const admin = await this.authenticateAsAdmin()
        return this.GET(this.endPoints.terms, admin)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1terms/get
    async listUserTermsAcceptance(userId: string, params: FilterParams<TermsFilter>) {
        const userAuth = await this.authenticateAsUser(userId)
        return this.GET(this.endPoints.userTerms({userId}) + withQueryParams(params), userAuth)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1status/get
    async getUserStatus(userId: string): Promise<UserStatus> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.GET(this.endPoints.userStatus({userId}), userAuth)
        return mapEntityOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1terms~1%7Bid%7D/patch
    async acceptTerms(userId: string, termsId: string, accepted: boolean) {
        const userAuth = await this.authenticateAsUser(userId)
        return this.PATCH(this.endPoints.userTerms({userId, termsId}), bodyOf('userTerms', {accepted}, {id: termsId}), userAuth)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1appointments/get
    async listAppointments(userId: string, params?: FilterParams<AppointmentsFilter> & PaginationParams): Promise<AppointmentOut[]> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.GET(this.endPoints.appointments() + withQueryParams(params), userAuth)
        return mapAppointmentsOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1appointments/get
    async listAllAppointments(userId: string, params?: FilterParams<AppointmentsFilter>): Promise<AppointmentOut[]> {
        const userAuth = await this.authenticateAsUser(userId)
        const maxPerPageParams = {...params, page: {size: 100}}
        const response = await this.GET(this.endPoints.appointments() + withQueryParams(maxPerPageParams), userAuth)
        const allResponses = await this.GETnexts(response, userAuth)
        return _.flatten(allResponses.map(mapAppointmentsOut))
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1appointments~1%7BappointmentId%7D~1hold/post
    async holdAppointment(userId: string, appointmentId: string): Promise<AppointmentHold> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.POST(this.endPoints.appointmentHolds({appointmentId}), bodyOf('appointmentHolds', {userId}), userAuth)
        return mapAppointmentHoldOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1appointments~1%7BappointmentId%7D/get
    async getAppointment(userId: string, appointmentId: string, params?: IncludeParams<AppointmentRelationships>): Promise<AppointmentOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.GET(this.endPoints.appointments({appointmentId}) + withQueryParams(params), userAuth)
        return mapEntityOut(response, 'start', 'end', 'hold.createdAt', 'hold.expiresAs', 'bookings.createdAt')
    }

    async getAppointmentHold(userId: string, appointmentId: string): Promise<AppointmentHold> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.GET(this.endPoints.appointmentHolds({appointmentId}), userAuth)
        return mapAppointmentHoldOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1bookings/post
    async createBooking(userId: string, dependantId: string | undefined, appointmentId: string, booking: BookingIn, params?: IncludeParams<BookingRelationships>): Promise<BookingOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const relationships = {
            appointment: {data: {type: 'appointments', id: appointmentId}},
            ...(dependantId ? {dependant: {data: {type: 'patients', id: dependantId}}} : {}),
        }
        const response = await this.POST(this.endPoints.bookings({userId}) + withQueryParams(params), bodyOf('bookings', booking, {relationships}), userAuth)
        return mapEntityOut(response, 'appointments.start', 'appointments.end')
    }

    async getBooking(userId: string, bookingId: string, params?: IncludeParams<BookingRelationships>): Promise<BookingOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.GET(this.endPoints.bookings({userId, bookingId}) + withQueryParams(params), userAuth)
        return mapBookingOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1bookings/get
    async listBookings(userId: string): Promise<BookingOut[]> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.GET(this.endPoints.bookings({userId}), userAuth)
        return mapListOfBookingsOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1bookings~1%7BbookingId%7D/delete
    async cancelBooking(userId: string, bookingId: string, reason?: BookingCancellationReason): Promise<BookingOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const reasonParam = reason ? '?cancellationReason=' + encodeURI(reason) : ''
        const response = await this.DELETE(this.endPoints.bookings({userId, bookingId}) + reasonParam, userAuth)
        return mapBookingOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1files/post
    async uploadFile(userId: string, fileUpload: FileUpload): Promise<FileOut> {
        const userAuth = await this.authenticateAsUser(userId)
        const response = await this.POST(this.endPoints.files({userId}), bodyOf('files', fileUpload), userAuth)
        return mapFileOut(response)
    }

    // https://medsol.stoplight.io/docs/ms-api/reference/Standard.v1.yaml/paths/~1api~1v1~1clients~1%7BclientId%7D~1users~1%7BuserId%7D~1bookings~1%7BbookingId%7D~1attachments/post
    async attachFileToBooking(userId: string, bookingId: string, fileId: string, description: string) {
        const userAuth = await this.authenticateAsUser(userId)
        const relationships = {file: {data: {type: 'files', id: fileId}}}
        return this.POST(this.endPoints.bookingAttachments({userId, bookingId}), bodyOf('attachments', {description}, {relationships}), userAuth)
    }

    buildConsultationEventHubFor(userId: string, consultationId: number, consultationSessionToken: string): ConsultationEventHub {
        const clientId = Number(this.clientId)
        this.debug('BUILDING HUB', this.endPoints.signalR, {clientId, consultationSessionToken})
        const connection = new HubConnectionBuilder().withUrl(this.endPoints.signalR, { accessTokenFactory: () => consultationSessionToken })
        .configureLogging(this.debugEnabled ? LogLevel.Trace : LogLevel.Error)
        .build()

        return {
            onConsultationEvent(eventHandler: (event: object) => void) {
                connection.on('consultationEvent', eventHandler)
            },

            async invokePatientConnected() {
                return connection.invoke('patientConnected', clientId, userId, consultationId)
            },

            connection
        }
    }

    // ----------------------------------------------------------

    protected async POST(endpoint: string, body: object = {}, auth?: AuthDetails) {
        this.applyCommonClientAttributesTo(body, 'POST')
        return this.http.POST(endpoint, body, config(auth))
    }

    protected async PATCH(endpoint: string, body: object, auth?: AuthDetails) {
        this.applyCommonClientAttributesTo(body, 'PATCH')
        return this.http.PATCH(endpoint, body, config(auth))
    }

    protected async GET(endpoint: string, auth?: AuthDetails) {
        return this.http.GET(endpoint, config(auth))
    }

    protected async DELETE(endpoint: string, auth?: AuthDetails) {
        return this.http.DELETE(endpoint, config(auth))
    }

    protected async GETnexts(response: {links: {next?: string}}, auth: AuthDetails, maxFollow = 20) {
        const allResponses = [response]
        let followed = 0
        while (response?.links?.next && followed <= maxFollow) {
            response = await this.GET(response.links.next, auth)
            allResponses.push(response)
            followed++
        }
        return allResponses
    }

    protected applyCommonClientAttributesTo(body: any, method: HttpMethod) {
        const commonClientAttributes = this.commonClientAttributesProvider?.(method)
        if (commonClientAttributes && body?.data?.attributes) {
            body.data.attributes.clientAttributes = {...this.commonClientAttributesProvider(method), ...body.data.attributes.clientAttributes || {}}
        }
    }

    protected async postFormDataReceiveJson(endpoint: string, body: any, auth?: AuthDetails) {
        const formDataConfig = config(auth, sendFormDataAndReceiveJsonHeaders)
        return this.http.fetchWithTimeout('POST', endpoint, formDataConfig, body)
    }

    protected debug(...stuff: any[]) {
        this.debugEnabled && console.log(...stuff)
    }
}


const noClientAuthId: string = ''
const noSecret: string = ''
export class PeachyProxiedHealthHeroClient extends HealthHeroClient {

    private proxiedEndpoints

    constructor(healthHeroDomain: string,
                readonly clientId: string,
                peachyHealthHeroProxyDomain: string,
                http: NupaHttpClient,
                private peachyAuthProvider: Pick<AuthProvider, 'fetchAuth'>) {
        super(healthHeroDomain, clientId, noClientAuthId, noSecret, http)
        this.proxiedEndpoints = buildEndpoints(peachyHealthHeroProxyDomain, clientId)
    }

    async fetchUserAuthDetails(userId: string): Promise<AuthDetails> {
        const json = await this.POST(this.proxiedEndpoints.auth({userId}), {}, await this.peachyAuthProvider.fetchAuth())
        return mapAuthDetails(json)
    }

    async createUser(user: UserIn): Promise<UserOut> {
        const response = await this.POST(this.proxiedEndpoints.adminUsers, bodyOf('users', user), await this.peachyAuthProvider.fetchAuth())
        return mapEntityOut(response)
    }

    async listUsers(params?: FilterParams<UsersFilter>): Promise<UserOut[]> {
        const response = await this.GET(this.proxiedEndpoints.adminUsers + withQueryParams(params), await this.peachyAuthProvider.fetchAuth())
        return mapListOfEntitiesOut(response)
    }

    async listTerms(): Promise<Terms[]> {
        const response = await this.GET(this.proxiedEndpoints.terms, await this.peachyAuthProvider.fetchAuth())
        return mapListOfEntitiesOut(response, 'activeFrom')
    }
}

function buildEndpoints(domain: string, clientId: string) {
    const baseUrl = 'https://' + domain
    const versionedBaseUrl = baseUrl + '/api/v1'
    const buildEndpoint = (root: string) => (baseEntity: string) => `${versionedBaseUrl}${root}/${clientId}${baseEntity}`
    const baseClientsUrl = buildEndpoint('/clients')
    const baseAdminClientsUrl = buildEndpoint('/admin/clients')

    return {
        adminUsers:             baseAdminClientsUrl('/users'),
        summary:                baseAdminClientsUrl('/reports/summary'),
        webhookEndpoints:       template(baseAdminClientsUrl('/restEndpoints/${endpointId}'), {endpointId: ''}),
        webhookConfigurations:  template(baseAdminClientsUrl('/webhooks/${webhookId}'),{webhookId: ''}),

        users:              template(baseClientsUrl('/users/${userId}'), {userId: ''}),
        auth:               template(baseClientsUrl('/oauth2/token/${userId}'), {userId: ''}),
        terms:              baseClientsUrl('/terms'),
        patientRecord:      template(baseClientsUrl('/users') + '/${userId}/patientRecord'),
        userTerms:          template(baseClientsUrl('/users') + '/${userId}/terms/${termsId}', {termsId: ''}),
        userStatus:         template(baseClientsUrl('/users') + '/${userId}/status'),
        bookings:           template(baseClientsUrl('/users') + '/${userId}/bookings/${bookingId}', {bookingId: ''}),
        bookingAttachments: template(baseClientsUrl('/users') + '/${userId}/bookings/${bookingId}/attachments'),
        dependants:         template(baseClientsUrl('/users') + '/${userId}/dependants/${dependantId}', {dependantId: ''}),
        files:              template(baseClientsUrl('/users') + '/${userId}/files'),
        appointments:       template(baseClientsUrl('/appointments') + '/${appointmentId}',{appointmentId: ''}),
        appointmentHolds:   template(baseClientsUrl('/appointments') + '/${appointmentId}/hold'),

        signalR:              versionedBaseUrl + '/signalr/consultationHub',

        baseUrl
    }
}

const sendFormDataAndReceiveJsonHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/vnd.api+json'
}

function bodyOf(type: string, attributes: object, {id, relationships}: {id?: string, relationships?: object} = {}) {
    return {data: {id, type, attributes, relationships}}
}

function withQueryParams(obj?: Partial<FilterParams<any> & PaginationParams & IncludeParams<string>>) {
    const {include, ...filterAndPagination} = obj ?? {}
    const filterAndPaginationParams = _.flatten(filterAndPagination ? Object.entries(filterAndPagination).map(([type, value]) => {
        return asQueryParamList(type, value)
    }) : [])
    const includeParam = include ? 'include=' + include.join(',') : undefined
    const allParams = removeUndefinedsFrom([...filterAndPaginationParams, includeParam])
    return allParams.length > 0 ? '?' + allParams.join('&') : ''
}

function asQueryParamList(keyWrapper: string, params: Dictionary<string | string[] | undefined>) {
    return params ? Object.entries(params).map( ([type, value]) => {
        const resolvedValue = value !== undefined && Array.isArray(value) ? `in:${value.join(',')}` : value as string
        return resolvedValue ? encodeURIComponent(`${keyWrapper}[${type}]`) + '=' + encodeURIComponent(resolvedValue) : undefined
    }).filter(it => it !== undefined) : []
}

function mapAuthDetails(json: any): AuthDetails {
    return AuthDetails.fromOidcAuthResponse(json)
}

function mapIncludedEntityOut(json: any, ...dateProperties: string[]) {
    return mapEntityOut({data: json}, ...dateProperties)
}

function mapEntityOut(json: any, ...dateProperties: string[]) {
    if (json?.data) {

        const mapped = {id: json.data.id, ...json.data.attributes}
        json.included?.forEach((included: any) => {
            mapped[included.type] = mapIncludedEntityOut(included)
        })
        dateProperties.push('createdAt')
        parseDates(mapped, ...dateProperties)
        return mapped
    }
}

function parseDates(obj: any, ...dateProperties: string[]) {
    new Set(dateProperties).forEach(dateProperty => {
        const nestedDatePropLocation = dateProperty.split('.')
        let nestedDateProp = obj[nestedDatePropLocation[0]]
        let i = 1
        let prevObj = obj
        for (;nestedDatePropLocation.length > 0 && i < nestedDatePropLocation.length; i++) {
            prevObj = nestedDateProp
            nestedDateProp = nestedDateProp?.[nestedDatePropLocation[i]]
        }
        if (nestedDateProp) {
            // @ts-ignore
            prevObj[nestedDatePropLocation[i-1]] = parseISO(nestedDateProp)
        }
    })
}

function mapListOfEntitiesOut(json: any, ...dateProperties: string[]) {
    return json.data ? json.data.map((it: any) => mapEntityOut({data: it}, ...dateProperties)) : []
}

function mapPatientRecordOut(json: any): PatientRecordOut {
    return mapEntityOut(json)
}

function mapAppointmentsOut(json: any): AppointmentOut[] {
    return mapListOfEntitiesOut(json, 'start', 'end')
}

function mapListOfPatientRecordsOut(json: any): PatientRecordOut[] {
    return mapListOfEntitiesOut(json)
}

function mapAppointmentHoldOut(json: any): AppointmentHold {
    return mapEntityOut(json, 'createdAt', 'expiresAt')
}

const bookingsDateParams = ['appointments.start', 'appointments.end', 'consultations.start', 'consultations.end', 'consultations.scheduled.start', 'consultations.scheduled.end']
function mapBookingOut(json: any): BookingOut {
    return mapEntityOut(json, ...bookingsDateParams)
}

function mapListOfBookingsOut(json: any): BookingOut[] {
    return mapEntityOut(json, ...bookingsDateParams)
}

function mapFileOut(json: any): FileOut {
    const file = mapEntityOut(json)
    file.size = Number(file.size)
    return file
}

function removeUndefinedsFrom(items: any[]) {
    return items.filter(it => !!it)
}

function config(auth: AuthDetails | undefined, headers?: Dictionary<string>): OptionalRequestConfig {
    return auth || headers ? {
        auth: auth ? {type: auth.token_type, token: auth.access_token} : undefined,
        headers
    } : undefined
}
