.wQdgsG_Spinner {
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  margin-bottom: 10vh;
  display: flex;
}

.wQdgsG_Spinner div {
  background-color: var(--peachy-pink);
  opacity: 0;
  border-radius: 1em;
  width: 2em;
  height: 2em;
  animation: .4s ease-out infinite alternate wQdgsG_pulse;
  transform: scale(0);
}

.wQdgsG_Spinner div:nth-child(2) {
  animation-delay: .125s;
}

.wQdgsG_Spinner div:nth-child(3) {
  animation-delay: .25s;
}

@keyframes wQdgsG_pulse {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7O0FBUUE7Ozs7Ozs7Ozs7QUFVQTs7OztBQUdBOzs7O0FBSUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9jb21wb25lbnRzL1NwaW5uZXIvU3Bpbm5lci5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5TcGlubmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgZ2FwOiAxZW07XG4gICAgbWFyZ2luLWJvdHRvbTogMTB2aDtcbn1cblxuLlNwaW5uZXIgZGl2IHtcbiAgICBoZWlnaHQ6IDJlbTtcbiAgICB3aWR0aDogMmVtO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXBlYWNoeS1waW5rKTtcbiAgICBib3JkZXItcmFkaXVzOiAxZW07XG4gICAgYW5pbWF0aW9uOiBwdWxzZSA0MDBtcyBlYXNlLW91dCBpbmZpbml0ZSBhbHRlcm5hdGU7XG4gICAgdHJhbnNmb3JtOiBzY2FsZSgwKTtcbiAgICBvcGFjaXR5OiAwO1xufVxuXG4uU3Bpbm5lciBkaXY6bnRoLWNoaWxkKDIpIHtcbiAgICBhbmltYXRpb24tZGVsYXk6IDEyNW1zO1xufVxuLlNwaW5uZXIgZGl2Om50aC1jaGlsZCgzKSB7XG4gICAgYW5pbWF0aW9uLWRlbGF5OiAyNTBtcztcbn1cblxuQGtleWZyYW1lcyBwdWxzZSB7XG4gICAgMCUge1xuICAgICAgICB0cmFuc2Zvcm06IHNjYWxlKDApO1xuICAgICAgICBvcGFjaXR5OiAwO1xuICAgIH1cbiAgICAxMDAlIHtcbiAgICAgICAgdHJhbnNmb3JtOiBzY2FsZSgxKTtcbiAgICAgICAgb3BhY2l0eTogMTtcbiAgICB9XG59XG4iXSwibmFtZXMiOltdfQ== */