import {
    AccountType,
    AppFeature,
    AppointmentType,
    BenefitType,
    ClaimStage,
    DecisionType,
    Gender,
    LifeType,
    PolicyStatus,
    Provenance
} from '../domain/types'
import {Dictionary, Draft, IsoDatePartOnly, KeyMapped} from '@peachy/utility-kit-pure'
import {Enquiry} from '../domain/enquiry/Enquiry'
import {Plan, Policy} from '@peachy/core-domain-pure'
import {BenefitUtilisationPolicy} from '../config/ProductConfigService'

export type RepoApprovedClaimCost = {
    planYearId: string
    benefitType: BenefitType
    amountInPence: number
}

export type RepoExessUsage = {
    excessId: string
    planYearId: string
    amountInPence: number
}

export type RepoDecision = {
    date: string,
    type: DecisionType
    notes?: string
    approvedCosts?: RepoApprovedClaimCost[] // if approved this will store a breakdown of approved costs per "plan year benefit". This is used to determine benefit usage
    excessUsage?: RepoExessUsage[]
}

export type RepoInProgressAppointmentBooking = {
    id: string
    type: AppointmentType
    dateCreated: string
    enquiryId: string
    serviceProviderMetadata: Dictionary<any>
}

export type RepoAppointment = RepoInProgressAppointmentBooking & {
    date: string
    whoForId: string
    whoWith: string
    location?: RepoAddress
    takesPlaceInApp: boolean
    attended: boolean
    dateCancelled?: string
}

export type RepoTerminologyItem = {
    system: string
    code: string
    display: string
}

export type RepoHospitalAdmission = {
    id: string
    admissionDate: string
    dischargeDate: string
}

export type RepoClaimActivitySubmissionReason = {
    id: string
    symptoms: RepoTerminologyItem[]
    disorder?: RepoTerminologyItem
    onsetDate: string
}

export type RepoClaimInvoiceLineItem = {
    id: string
    procedure: RepoTerminologyItem
    treatmentAddress?: Partial<RepoAddress>
    treatmentDate: string
    treatmentPaymentDate: string
    planYearId: string
    benefitType: BenefitType
    hospitalAdmissionId?: string
    invoiceAmountInPence: number
    eligibleAmountInPence: number
    reasonId: string
    notes?: string
}

export type RepoCoverCheckRequestedTreatment = {
    id: string
    procedure: RepoTerminologyItem
    reasonId: string
    benefitType: BenefitType
    approved: boolean
    notes?: string
}

export type RepoClaimAssessment = {
    referralDate?: string
    submissionReasons: RepoClaimActivitySubmissionReason[]
    hospitalAdmissions: RepoHospitalAdmission[]
    invoiceLineItems: RepoClaimInvoiceLineItem[]
    linkedClaimActivityIds: string[]
    requestedTreatments: RepoCoverCheckRequestedTreatment[]
}
export type RepoInProgressClaimActivity = {
    id: string
    stage: ClaimStage
    dateCreated: string
    enquiryId: string
}

export type RepoClaimSubmissionId = {
    message: {id: string}
    conversation: {id?: string}
}

export type RepoClaimActivity = RepoInProgressClaimActivity & {
    customerDeclaredBenefitType: string
    submissionId: RepoClaimSubmissionId // Intercom conversation id
    dateSubmitted: string
    decision?: RepoDecision
    treatment?: string // customer declared treatment (only set for certain benefits.  Enquiry Definition determins if this is asked or not)
    treatmentReceiverId: string // could be app owner, or a dependent
    costInPence?: number // customer declared treatment cost
    treatmentDate?: string // customer declared
    amountReimbursedInPence?: number // only set once the payment has been made
    assessment?: RepoClaimAssessment // where the claim processing tool in the Pit stores it's assessment
    provenance?: Provenance //who entered the claim? (could be claims assessor if user raised the claim over intercom and CA had to manually enter it after the fact)
}

type RepoBenefitUtilisationPolicy = BenefitUtilisationPolicy

export type RepoBenefit = {
    id: string
    type: string
    parentType?: string
    startDate: string
    endDate?: string
    utilisationPolicy?: RepoBenefitUtilisationPolicy
}

export type RepoExcess = {
    id: string
    benefitTypes: BenefitType[]
    amountInPence: number
}

export type RepoGeoLocation = {
    latitude: number
    longitude: number
}

export type RepoAddress = {
    line1: string
    line2?: string
    city: string
    county?: string
    postcode: string
    country: string
    geolocation: RepoGeoLocation
}

export type RepoGp = {
    id: string
    practiceName: string
    address: RepoAddress
    nhsOrganisationId?: string
}

export type RepoBankDetails = {
    accountNumber: string
    sortCode: string
}

export type RepoLife = {
    id: string
    awsSub?: string
    firstName: string
    lastName: string
    email?: string
    phoneNumber?: string
    dateOfBirth: IsoDatePartOnly
    gender?: Gender
    address?: RepoAddress
    registeredGp?: RepoGp
    onboarded: boolean
    isPolicyOwner: boolean
    isPrimary: boolean
    type: LifeType
    vgpUserId?: string
    vgpAcceptedTerms?: string[]
    dateCancelled?: string
    bankAccount?: RepoBankDetails
}

export type RepoPlan = {
    id: string
    lifeId: string
    startDate: string
    benefits: RepoBenefit[]
    excess?: RepoExcess
    
    packagedPlanId?: string
    /**
     * @deprecated this is a meaningless reference to the original quote
     */
    quotePlanId: string
}


export type RepoSubscription = {
    startDate: string
}

export type RepoPolicy = {
    id: string
    accountType: AccountType
    owner: {
        id: string,
        awsSub: string
    }
    startDate: string
    status: PolicyStatus
}

export type RepoPreferenceItem = {
    id: string
    description: string
    allow: boolean
}

export type RepoPreferences = Dictionary<RepoPreferenceItem[]>

export type RepoAppSettings = {
    preferences: RepoPreferences
}

export type RepoInProgress = {
    CLAIM?: RepoInProgressClaimActivity
    COVER_CHECK?: RepoInProgressClaimActivity
    VIRTUAL_GP?: RepoInProgressAppointmentBooking
    PHYSIO?: RepoInProgressAppointmentBooking
}

export type RepoInProgressTypes = keyof RepoInProgress

export type RepoPolicyRequest = Draft<{
    policy: Policy
    plans: KeyMapped<Plan>
}>

export type RepoEnquiry = Draft<Enquiry>
export type RepoEnquiriesById = Dictionary<RepoEnquiry>
export type RepoAppointmentById = Dictionary<RepoAppointment>

export type RepoLivesById = Dictionary<RepoLife>

export type RepoPlanUpgradesByLifeId = Dictionary<RepoPlanUpgrade>

export type RepoPlanUpgrade = {
    dateUpgraded: string
    fromPackagedPlanId?: string
    toPackagedPlanId: string
    premiumUpliftInPence: number
}

export type PeachyRepoRoot = {
    subscription: RepoSubscription
    policy: RepoPolicy
    plans: RepoPlan[]
    settings: RepoAppSettings
    inProgress: RepoInProgress
    enquiries: RepoEnquiriesById
    appointments: RepoAppointmentById
    upgrades?: RepoPlanUpgradesByLifeId

    managedLives: RepoLivesById
    nonManagedLives: RepoLivesById
    claimActivities: RepoClaimActivity[]
    enabledFeatureToggles?: AppFeature[]
}
