import {
    QuestionIds,
    QuestionInitialisationData,
    QuestionInitialiser,
    QuestionInitialiserAndConfigTuple
} from '../../types'
import {
    gotItOptions,
    imReadyOptions,
    noOption,
    reviewSubmitOptions,
    therapiesBenefitOption,
    therapistOptions,
    useSavedAccountOption,
    whoOptions,
    yesNoOptions,
    yesOption
} from '@peachy/repo-domain'
import {EnquiryQuestionDataModelProviders} from '../../EnquiryQuestionDataModelProviders'
import {max, subYears} from 'date-fns'
import {EnquiryDefinitionCommon} from '../EnquiryDefinitionCommon'
import {
    buttonQuestion,
    captureImagesAndFilePickerQuestion,
    fullDatePickerQuestion,
    recordVideoQuestion,
    requiredWhenMoreThanOneOption,
    signatureQuestion,
    singleAnswerBankDetailsQuestion,
    singleAnswerCheckboxQuestion,
    singleAnswerFreetextQuestion,
    when
} from '../EnquiryDefinitionUtil'

export class MakeClaimEnquiryDefinition extends EnquiryDefinitionCommon {

    readonly id = 'make-claim'
    readonly questions: QuestionInitialiserAndConfigTuple[]

    constructor(protected readonly dataModelProviders: EnquiryQuestionDataModelProviders) {
        super()
        this.questions = this.buildQuestions()
    }

    buildQuestions(): QuestionInitialiserAndConfigTuple[] {
        return [
            // unasked.. i.e expected to be initiated before chat
            [this.whoIsTheAppOwner],
            [this.whatAreTheSavedBankDetails],

            [this.whoAreYouClaimingFor, requiredWhenMoreThanOneOption],
            [this.whatAreYouClaimingFor],
            [this.haveYouCheckedYourCover],
            [this.rememberToCheckCoverNextTime, {
                required: when({
                    answersOf: [[this.haveYouCheckedYourCover, noOption]]
                })
            }],
            [this.whatTypeOfTherapistDidYouSee, {
                required: when({
                    answersOf: [[this.whatAreYouClaimingFor, therapiesBenefitOption]]
                })
            }],
            [this.whenTreated],
            [this.prepareToRecordVideo],
            [this.recordWhatWasWrongVideo],
            [this.recordWhatTreatmentsVideo],
            [this.recordAnythingElseVideo],
            [this.prepareToCaptureReceipts],
            [this.captureReceipts],
            [this.collectBankAccountDetails],
            [this.saveOrReplaceBankAccountDetails, {
                required: when({
                    noAnswersOf: [[this.collectBankAccountDetails, useSavedAccountOption]]
                })
            }],
            [this.claimingOnAnotherPolicy],
            [this.otherPolicyDetails, {
                required: when({
                    answersOf: [[this.claimingOnAnotherPolicy, yesOption]]
                })
            }],
            [this.signPromise],
            [this.reviewAndSubmitClaim],
        ]
    }

    get whatAreTheSavedBankDetails (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerBankDetailsQuestion({
            id: question.id,
            text: 'What are the saved bank details?',
            tags: [
                // should capture name but just make it non-editable in the view and initialise it when the enquiry starts
                `${EnquiryDefinitionCommon.accHolderNameTag}${policy.primaryLife.fullName}`,
                EnquiryDefinitionCommon.hideQuestionInChatTag
            ]
        })
        question.id = QuestionIds['saved-bank-account-details']
        return question
    }

    get whoAreYouClaimingFor (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerCheckboxQuestion({
            id: question.id,
            text: `Hi ${policy.primaryLife.firstName}, who are you claiming for today?`,
            embeddedOptions: whoOptions(policy.managedLivesThatAreAllowedToMakeClaims)
        })
        question.id = QuestionIds['who-are-you-claiming-for']
        return question
    }


    get haveYouCheckedYourCover (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Did you check cover before making this claim?',
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['have-you-checked-your-cover']
        return question
    }


    get rememberToCheckCoverNextTime (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: "Don't worry! We'll always pay a claim if you're covered, but next time please remember to check cover before you get care and make a claim. This gives you peace of mind that you're covered",
            embeddedOptions: gotItOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['remember-to-check-cover-next-time']
        return question
    }


    get whatAreYouClaimingFor (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Ok, what are you claiming for today?',
            optionsId: 'claimable-benefits'
        })
        question.id = QuestionIds['what-are-you-claiming-for']
        return question
    }


    get whatTypeOfTherapistDidYouSee (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'What type of therapist did you see?',
            embeddedOptions: therapistOptions
        })
        question.id = QuestionIds['what-type-of-therapist-did-you-see']
        return question
    }


    get whenTreated (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => {
            const oneYearAgo = subYears(new Date(), 1)
            const minAccepted = max([policy.startDate, oneYearAgo])

            return fullDatePickerQuestion({
                id: question.id,
                text: 'When did ${whoFor ? whoFor : "you"} get care?',
                minAccepted,
                maxAccepted: new Date()
            })
        }
        question.id = QuestionIds['when-treated']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get prepareToRecordVideo (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: `Great, thank you! Now lets record a video selfie of you describing your claim
    
    Tell us:
    
    1. What was wrong\${whoFor ? ' with ' + whoFor : ''}, the symptoms \${whoFor ? 'they' : 'you'} were experiencing & the diagnosis (if you know it)
    
    2. What care \${whoFor ? whoFor : 'you'} received
    
    3. Anything else that you think might be relevant
    
    Don't worry about writing this down, we'll prompt you! You'll get a chance to review the video before you submit.
    
    Before you start, please try to make sure you're in a well lit, quiet area and keep the phone as still as possible
    `,
            embeddedOptions: imReadyOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['prepare-to-record-video']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get recordWhatWasWrongVideo (): QuestionInitialiser {
        const question = () => recordVideoQuestion({
            id: question.id,
            text: "1. Tell us what was wrong${whoFor ? ' with ' + whoFor : ''}, the symptoms ${whoFor ? 'they' : 'you'} were experiencing & the diagnosis (if you know it)",
            tags: [EnquiryDefinitionCommon.transcribeMedicalAudioTag]
        })
        question.id = QuestionIds['record-what-was-wrong-video']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get recordWhatTreatmentsVideo (): QuestionInitialiser {
        const question = () => recordVideoQuestion({
            id: question.id,
            text: "2. Tell us what investigations / treatment ${whoFor ? whoFor : 'you'} received",
            tags: [EnquiryDefinitionCommon.transcribeMedicalAudioTag]
        })
        question.id = QuestionIds['record-what-treatments-video']
        question.dataModelProviders = [this.dataModelProviders.whoIfNotPrimaryLifeDataModelProvider()]
        return question
    }


    get recordAnythingElseVideo (): QuestionInitialiser {
        const question = () => recordVideoQuestion({
            id: question.id,
            text: `3. Tell us anything else that you think might be relevant`,
            tags: [EnquiryDefinitionCommon.transcribeMedicalAudioTag]
        })
        question.id = QuestionIds['record-anything-else-video']
        return question
    }


    get prepareToCaptureReceipts (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: "Thanks! Now we just need some pictures of your receipt(s) and any other documents you think we might need. Please make sure you capture all pages. If you don't have it right now, you can come back to this point later when you do",
            embeddedOptions: imReadyOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['prepare-to-capture-receipts']
        return question
    }


    get captureReceipts (): QuestionInitialiser {
        const question = () => captureImagesAndFilePickerQuestion({
            id: question.id,
            text: '',
            tags: [EnquiryDefinitionCommon.textractInvoiceTag]
        })
        question.id = QuestionIds['capture-receipts']
        return question
    }


    get collectBankAccountDetails (): QuestionInitialiser {
        const question = ({policy}: QuestionInitialisationData) => singleAnswerBankDetailsQuestion({
            id: question.id,
            text: 'Please add your bank details so we can process your payment as fast as possible!',
            // should capture name but just make it non-editable in the view and initialise it when the enquiry starts rather than using tags
            tags: [`${EnquiryDefinitionCommon.accHolderNameTag}${policy.primaryLife.fullName}`]
        })
        question.id = QuestionIds['collect-bank-account-details']
        question.dataModelProviders = [this.dataModelProviders.savedBankDetailsDataModelProvider()]
        return question
    }

    get saveOrReplaceBankAccountDetails (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: "Do you want us to ${obfuscatedSavedBankAccount ? 'replace your existing' : 'save these'} bank details for next time?",
            embeddedOptions: yesNoOptions
        })
        question.dataModelProviders = [this.dataModelProviders.savedBankDetailsDataModelProvider()]
        question.id = QuestionIds['should-save-bank-account-details']
        return question
    }


    get claimingOnAnotherPolicy (): QuestionInitialiser {
        const question = () => singleAnswerCheckboxQuestion({
            id: question.id,
            text: 'Are you making a claim for this treatment under another policy?',
            embeddedOptions: yesNoOptions
        })
        question.id = QuestionIds['claiming-on-another-policy']
        return question
    }


    get otherPolicyDetails (): QuestionInitialiser {
        const question = () => singleAnswerFreetextQuestion({
            id: question.id,
            text: 'Please provide the details of the other policy, including the name of the company and policy number'
        })
        question.id = QuestionIds['other-policy-details']
        return question
    }


    get signPromise (): QuestionInitialiser {
        const question = () => signatureQuestion({
            id: question.id,
            text: 'Here at Peachy we believe in trust!',
            helpHeading: 'Your promise to us',
            helpText: 'By signing this statement, I confirm that the information provided for this claim is true and accurate'
        })
        question.id = QuestionIds['sign-promise']
        return question
    }


    get reviewAndSubmitClaim (): QuestionInitialiser {
        const question = () => buttonQuestion({
            id: question.id,
            text: 'Great! Please take a moment to review this claim before you submit',
            embeddedOptions: reviewSubmitOptions,
            tags: EnquiryDefinitionCommon.hideAnswersInChat
        })
        question.id = QuestionIds['submit-claim']
        return question
    }

}
