export type MediaUpload = {
    source: string
    target: string
    type: string
    processingRequired: string[]
    failureCount?: number
}

export interface IMediaUploadService {
    add(media: MediaUpload): Promise<void>
    upload(): Promise<void>
}