import { ClaimStages } from '../domain/types'
import { BenefitConfigUtilisationPolicy, Obligations, ProductConfigService } from './ProductConfigService'

const mandatoryClaim: Pick<BenefitConfigUtilisationPolicy, 'CLAIM' | 'COVER_CHECK'> = {[ClaimStages.CLAIM]: {obligation: Obligations.MANDATORY, lodgementWindowInWeeks: 16}}
const encouragedCoverCheck: Pick<BenefitConfigUtilisationPolicy, 'CLAIM' | 'COVER_CHECK'> = {[ClaimStages.COVER_CHECK]: {obligation: Obligations.ENCOURAGED}}
const optionalCoverCheck: Pick<BenefitConfigUtilisationPolicy, 'CLAIM' | 'COVER_CHECK'> = {[ClaimStages.COVER_CHECK]: {obligation: Obligations.OPTIONAL}}

export const peachyProductConfigService = new ProductConfigService({
    benefitsByType: {
        DENTAL: {
            type: 'DENTAL',
            displayName: 'Dental',
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim,
                ...optionalCoverCheck
            }
        },
        OPTICAL: {
            type: 'OPTICAL',
            displayName: 'Optical',
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim,
                ...optionalCoverCheck
            }
        },
        THERAPIES: {
            type: 'THERAPIES',
            displayName: 'Therapies',
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim,
                ...encouragedCoverCheck
            },
            drivesFeatures: ['BOOK_PHYSIO']
        },
        MENTAL_HEALTH_IN_PATIENT: {
            type: 'MENTAL_HEALTH_IN_PATIENT',
            displayName: 'Mental health (in patient)',
            parentType: 'MENTAL_HEALTH',
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim,
                ...encouragedCoverCheck
            }
        },
        MENTAL_HEALTH_OUT_PATIENT: {
            type: 'MENTAL_HEALTH_OUT_PATIENT',
            displayName: 'Mental health (out patient)',
            parentType: 'MENTAL_HEALTH',
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim,
                ...encouragedCoverCheck
            }
        },
        CONSULTATIONS_AND_DIAGNOSTICS: {
            type: 'CONSULTATIONS_AND_DIAGNOSTICS',
            displayName: 'Consultations & diagnostics',
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim,
                ...encouragedCoverCheck
            }
        },
        HOSPITAL_CARE: {
            type: 'HOSPITAL_CARE',
            displayName: 'Hospital care',
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim,
                ...encouragedCoverCheck
            }
        },
        VIRTUAL_GP: {
            type: 'VIRTUAL_GP',
            displayName: 'Virtual GP',
            offerAsUpsell: true,
            drivesFeatures: ['BOOK_VGP']
        },
        ADVICE_AND_COUNSELLING: {
            type: 'ADVICE_AND_COUNSELLING',
            displayName: 'Advice and counselling',
            offerAsUpsell: false,
            drivesFeatures: ['ADVICE_AND_COUNSELLING']
        }
    },
    parentBenefitsByType: {
        MENTAL_HEALTH: {
            type: 'MENTAL_HEALTH',
            displayName: 'Mental health',
            utilisationPolicy: {
                ...mandatoryClaim,
                ...encouragedCoverCheck
            }
        }
    }
})
