import {
    dump,
    groupByNested,
    maxValidDate,
    newUUID,
    Optional,
    PropertiesOnly,
    ukStyleDate,
    WriteablePropertiesOnly
} from '@peachy/utility-kit-pure'
import {Accessor, batch, createEffect, createMemo, createResource, createSignal, on, Setter} from 'solid-js'
import {addWeeks, differenceInDays, isAfter, isBefore, isEqual, isValid, setHours} from 'date-fns'
import {assign, isEmpty, isUndefined, orderBy, sortBy, sumBy, uniq, uniqBy} from 'lodash-es'
import {
    ClaimActivityEnquiryReader,
    ClaimsSearchService,
    ClaimsService,
    EnquiryReader,
    MakeClaimEnquiryReader,
    RepoManagementService
} from '@peachy/service'
import {createListStore, ListStoreApi} from '@peachy/client-kit'
import {
    ApprovedClaimCosts,
    Benefit,
    BenefitType,
    BenefitWithUsage,
    ClaimActivity,
    ClaimActivitySubmissionReason,
    ClaimInvoiceLineItem,
    CoverCheckRequestedTreatment,
    Enquiry,
    ExcessWithUsage,
    HospitalAdmission,
    PeachyBenefitTypes,
    Plan,
    PlanYear,
    PlanYearBenefitAmount,
    PlanYearBenefitAmountTotals,
    prettyPrintClaimStage,
    ProductConfigService,
} from '@peachy/repo-domain'
import {useBean} from '../../../controllers/CustomerApplicationContextProvider'
import {createStore, SetStoreFunction, Store} from 'solid-js/store'
import {NhsTerminologyServerClient} from '@peachy/nhs-pure'
import {useApiInfra} from '../../../controllers/InfraController'

export type PageSectionId = 'admissionsCapture' | 'reasonCapture' | 'invoiceCaptue' | 'treatmentCapture'

export type HasId = {id: string | number}

export type WritableClaimActivitySubmissionReason = WriteablePropertiesOnly<ClaimActivitySubmissionReason & {readOnly?: boolean}>
export type ValidatableClaimActivitySubmissionReason = Validatable<WritableClaimActivitySubmissionReason>

export type WritableClaimInvoiceLineItem = WriteablePropertiesOnly<ClaimInvoiceLineItem>
export type ValidatableClaimInvoiceLineItem = Validatable<WritableClaimInvoiceLineItem>

export type WritableHospitalAdmission = WriteablePropertiesOnly<HospitalAdmission>
export type ValidatableHospitalAdmission = Validatable<WritableHospitalAdmission>

export type WritableCoverCheckRequestedTreatment = WriteablePropertiesOnly<CoverCheckRequestedTreatment>
export type ValidatableCoverCheckRequestedTreatment = Validatable<WritableCoverCheckRequestedTreatment>


export class ClaimAssessmentState {

    readonly claim: Accessor<ClaimActivity>
    readonly refetchClaim: () => Promise<ClaimActivity> | ClaimActivity

    readonly linkedCoverChecks: Accessor<ClaimActivity[]>
    readonly setLinkedCoverChecks: Setter<ClaimActivity[]>

    readonly reasonsForClaimActivity: Store<ValidatableClaimActivitySubmissionReason[]>
    readonly reasonsForClaimActivityApi: ListStoreApi<ValidatableClaimActivitySubmissionReason>

    readonly hospitalAdmissions: Store<ValidatableHospitalAdmission[]>
    readonly hospitalAdmissionsApi: ListStoreApi<ValidatableHospitalAdmission>

    readonly invoiceLineItems: Store<ValidatableClaimInvoiceLineItem[]>
    readonly invoiceLineItemsApi: ListStoreApi<ValidatableClaimInvoiceLineItem>

    readonly requestedTreatments: Store<ValidatableCoverCheckRequestedTreatment[]>
    readonly requestedTreatmentsApi: ListStoreApi<ValidatableCoverCheckRequestedTreatment>

    readonly highlighted: Store<HasId[]>
    readonly highlightedApi: ListStoreApi<HasId>

    readonly claimEnquiryReader: ClaimActivityEnquiryReader

    readonly showSpinner: Accessor<boolean>
    private readonly setShowSpinner: Setter<boolean>

    private claimsService: ClaimsService
    private claimsSearchService: ClaimsSearchService
    private repoManagementService: RepoManagementService

    private terminologyClient: NhsTerminologyServerClient

    private productConfigService: ProductConfigService

    constructor(
        claimActivityId: string,
        private readonly enquiry: Enquiry,
        private readonly plan: Plan,
        private readonly otherClaimActivities: ClaimActivity[],
        readonly readOnly: boolean,
        readonly forceEditMode: boolean,
        readonly onSaveOrApplyDecision?: () => void) {

            this.claimEnquiryReader = EnquiryReader.forClaimActivity(this.enquiry);

            [this.linkedCoverChecks, this.setLinkedCoverChecks] = createSignal<ClaimActivity[]>([]);

            [this.reasonsForClaimActivity, this.reasonsForClaimActivityApi] = createListStore<ValidatableClaimActivitySubmissionReason>();
            [this.hospitalAdmissions, this.hospitalAdmissionsApi] = createListStore<ValidatableHospitalAdmission>();
            [this.invoiceLineItems, this.invoiceLineItemsApi] = createListStore<ValidatableClaimInvoiceLineItem>();

            [this.requestedTreatments, this.requestedTreatmentsApi] = createListStore<ValidatableCoverCheckRequestedTreatment>();
            [this.highlighted, this.highlightedApi] = createListStore<HasId>([]);

            [this.showSpinner, this.setShowSpinner] = createSignal<boolean>(false)

            this.createEffectsToValidateAllValidatablesWhenTheyChange()

            this.claimsService = useBean('claimsService')
            this.claimsSearchService = useBean('claimsSearchService')
            this.repoManagementService = useBean('repoManagementService')
            this.productConfigService = useBean('productConfigService')

            ;[this.claim, {refetch: this.refetchClaim}] = createResource(claimActivityId, id => this.claimsSearchService.getClaimActivity(id))

            this.terminologyClient = NhsTerminologyServerClient.usingAmplifyApi('NhsApi', useApiInfra())

            const claimChanged = createMemo(() => this.claim()?.id)
            createEffect(on(claimChanged, () => {
                if (this.claim()) {
                    console.log('on claim changed')
                    batch(() => {
                        const {submissionReasons = [], hospitalAdmissions = [], invoiceLineItems, linkedClaimActivityIds = [], requestedTreatments} = this.claim().assessment ?? {}

                        const initReasonsForClaimActivity = !isEmpty(submissionReasons) ? submissionReasons :
                            !isEmpty(linkedClaimActivityIds) ? [] : [this.getDraftReasonForClaimActivity()]
                        this.reasonsForClaimActivityApi.set(initReasonsForClaimActivity.map(asValidatable))

                        if (this.claim().isClaim()) {
                            const initInvoiceLineItems = !isEmpty(invoiceLineItems) ? invoiceLineItems : [this.getDraftInvoiceLineItem()]
                            this.invoiceLineItemsApi.set(initInvoiceLineItems.map(asValidatable))
                            this.hospitalAdmissionsApi.set(hospitalAdmissions.map(asValidatable))
                            this.setLinkedCoverChecks(this.coverChecks().filter(it => linkedClaimActivityIds.includes(it.id)))
                        }

                        if (this.claim().isCoverCheck()) {
                            const initRequestedTreatments = !isEmpty(requestedTreatments) ? requestedTreatments : [this.getDraftRequestedTreatment()]
                            this.requestedTreatmentsApi.set(initRequestedTreatments.map(asValidatable))
                        }
                    })
                }
            }))
    }

    async searchConditionsTerminology(stem: string) {
        const disordersConceptId = '64572001'
        return this.searchSnomedIsA(stem, disordersConceptId)
    }

    async searchProceduresTerminology(stem: string) {
        const proceduresConceptId = '71388002'
        return this.searchSnomedIsA(stem, proceduresConceptId)
    }

    async searchSymptomsTerminology(stem: string) {
        const clinicalFindingsConceptId = '404684003'
        return this.searchSnomedIsA(stem, clinicalFindingsConceptId)
    }

    private async searchSnomedIsA(stem: string, conceptRootId: string) {
        return NhsTerminologyServerClient.terminologyIn(await this.terminologyClient.searchSnomedIsA(stem, conceptRootId))
    }

    isReferred() {
        return !!this.claim().assessment?.referralDate
    }

    claimIsApproved() {
        return this.claim().isApproved()
    }

    async referAndSync(referralDate: Date) {
        this.spin()
        try {
            await this.claimsService.refer(this.claim().id, referralDate)
            await this.repoManagementService.syncRepoWithRemote(true)
            await this.refetchClaim()
        } catch (e) {
            dump(e)
        }
        this.stopSpin()
    }

    async saveAssessmentAndSync() {
        const andSync = true
        return this.saveAssessment(andSync)
    }

    async syncRepoAndRefetchClaim() {
        dump('enter sync...')
        const syncResponse = await this.repoManagementService.syncRepoWithRemote(true)
        if (this.repoManagementService.didNotSync(syncResponse)) {
            dump('not synchronised, repo must have been modified, re-syncing...')
            await this.repoManagementService.syncRepoWithRemote(true)
        }
        dump('...and refresh')
        await this.refetchClaim()
    }

    async saveAssessment(andSync = false) {
        dump('enter save assessment')
        let actioned = false
        const anyErrors = this.getAndShowAnyFieldErrors()
        if (!anyErrors) {
            this.spin()
            try {
                await this.claimsService.saveAssessment(this.claim().id, {
                    hospitalAdmissions: this.hospitalAdmissions,
                    invoiceLineItems: this.invoiceLineItems,
                    submissionReasons: this.reasonsForClaimActivity,
                    linkedClaimActivityIds: this.linkedCoverChecks().map(it => it.id),
                    requestedTreatments: this.requestedTreatments
                })
                if (andSync) {
                    await this.syncRepoAndRefetchClaim()
                }
                actioned = true
            } catch (e) {
                dump(e)
                actioned = false
            } finally {
                this.stopSpin()
            }
        }
        dump(`saved: ${actioned}`)
        return actioned
    }

    async declineAndSync(reason: string) {
        dump('enter decline and sync')
        let actioned = false
        this.spin()
        try {
            if (await this.saveAssessment()) {
                this.spin()
                await this.claimsService.decline(this.claim().id, reason, new Date())
                await this.syncRepoAndRefetchClaim()
                actioned = true
            }
        } catch(e) {
            dump(e)
            actioned = false
        } finally {
            this.stopSpin()
        }
        dump(`declined ${actioned}`)
        this.onSaveOrApplyDecision?.()
        return actioned
    }

    async approveAndSync(approvedCosts?: ApprovedClaimCosts) {
        let actioned = false
        this.spin()
        try {
            if (await this.saveAssessment()) {
                this.spin()
                await this.claimsService.approve(this.claim().id, approvedCosts, new Date())
                await this.syncRepoAndRefetchClaim()
                actioned = true
            }
        } catch(e) {
            dump(e)
            return false
        } finally {
            this.stopSpin()
        }
        this.onSaveOrApplyDecision?.()
        return actioned
    }

    private getDraftInvoiceLineItem(basedOn?: ClaimInvoiceLineItem): WritableClaimInvoiceLineItem {
        const thisWillBeTheFirstItem = !basedOn
        const invoiceAmountInPence = thisWillBeTheFirstItem ? (this.customerDeclaredCostInPence ?? 0) : 0

        return {
            id: newUUID(),
            procedure: undefined,
            treatmentDate: basedOn?.treatmentDate ?? this.customerDeclaredTreatmentDate ?? new Date(),
            treatmentPaymentDate: basedOn?.treatmentPaymentDate ?? this.customerDeclaredTreatmentDate ?? new Date(),
            invoiceAmountInPence,
            eligibleAmountInPence: invoiceAmountInPence,
            reasonId: undefined,
            planYearId: basedOn?.planYearId ?? this.plan.currentPlanYear?.id,
            benefitType: this.ifBenefitTypeIsEverClaimable(basedOn?.benefitType ?? this.customerDeclaredBenefitType)
        }
    }
    addDraftInvoiceLineItem({highlight} = {highlight: false}) {
        const draft = asValidatable<WritableClaimInvoiceLineItem>(this.getDraftInvoiceLineItem(this.invoiceLineItemsApi.last()))
        this.invoiceLineItemsApi.push(draft)
        this.optionallyScrollToAndHighlight(draft, 'invoiceCaptue', highlight)
    }

    private getDraftReasonForClaimActivity(basedOn?: ClaimActivitySubmissionReason): WritableClaimActivitySubmissionReason {
        return {
            id: newUUID(),
            disorder: undefined,
            symptoms: [],
            onsetDate: basedOn?.onsetDate ?? this.customerDeclaredSymptomsOnsetDate ?? new Date()
        }
    }
    addDraftReasonForClaimActivity({highlight} = {highlight: false}) {
        const firstInvalidReason = this.scrollToAndHighlightFirstInvalid(this.reasonsForClaimActivity, 'reasonCapture')

        if (firstInvalidReason) {
            return firstInvalidReason
        }

        const draft = asValidatable<WritableClaimActivitySubmissionReason>(this.getDraftReasonForClaimActivity(this.reasonsForClaimActivityApi.last()))
        this.reasonsForClaimActivityApi.push(draft)
        this.optionallyScrollToAndHighlight(draft, 'reasonCapture', highlight)
        return draft

    }
    addDraftHospitalAdmission({highlight} = {highlight: false}) {
        const firstInvalidAdmission = this.scrollToAndHighlightFirstInvalid(this.hospitalAdmissions, 'admissionsCapture')

        if (firstInvalidAdmission) {
            return firstInvalidAdmission
        }

        const lastInvoiceLineItem = this.invoiceLineItemsApi.last()

        const draft = asValidatable<WritableHospitalAdmission>({
            id: newUUID(),
            admissionDate: lastInvoiceLineItem?.treatmentDate ?? new Date(),
            dischargeDate: maxValidDate(lastInvoiceLineItem?.treatmentPaymentDate, lastInvoiceLineItem?.treatmentDate)  ?? new Date()
        })
        this.hospitalAdmissionsApi.push(draft)
        this.optionallyScrollToAndHighlight(draft, 'admissionsCapture', highlight)
        return draft
    }

    private getDraftRequestedTreatment(basedOn?: CoverCheckRequestedTreatment): WritableCoverCheckRequestedTreatment {
        return {
            id: newUUID(),
            procedure: undefined,
            reasonId: undefined,
            benefitType: this.ifBenefitTypeIsEverCoverCheckable(basedOn?.benefitType ?? this.customerDeclaredBenefitType),
            approved: false
        }
    }
    addDraftRequestedTreatments({highlight} = {highlight: false}) {
        const draft = asValidatable<WritableCoverCheckRequestedTreatment>(this.getDraftRequestedTreatment(this.requestedTreatmentsApi.last()))
        this.requestedTreatmentsApi.push(draft)
        this.optionallyScrollToAndHighlight(draft, 'treatmentCapture', highlight)
    }

    get customerDeclaredTreatmentDate() {
        return this.claim().treatmentDate
    }

    get customerDeclaredTreatment() {
        return this.claim().treatment
    }

    get customerDeclaredSymptomsOnsetDate(): Date {
        return this.claimEnquiryReader?.extractSymptomsOnsetDateFrom?.(this.enquiry)
    }

    get customerDeclaredCostInPence() {
        return this.claim().costInPence
    }

    get customerDeclaredBenefitType() {
        return this.claim().customerDeclaredBenefitType
    }

    get customerDeclaredBenefitText() {
        return this.productConfigService.getBenefitDisplayName(this.claim().customerDeclaredBenefitType)
    }

    suggestedPlanYearFor(invoiceLineItem: ValidatableClaimInvoiceLineItem) {
        const latestOfTreatmentAndDischargeDate = maxValidDate(
            invoiceLineItem.treatmentDate,
            this.hospitalAdmissionAssociatedWith(invoiceLineItem)?.dischargeDate
        )
        return latestOfTreatmentAndDischargeDate ? this.plan.getPlanYearAtDateOf(latestOfTreatmentAndDischargeDate) : this.plan.currentPlanYear
    }

    hospitalAdmissionAssociatedWith(invoiceLineItem: WritableClaimInvoiceLineItem) {
        return this.hospitalAdmissions.find(it => it.id === invoiceLineItem.hospitalAdmissionId)
    }

    get planYears() {
        return this.plan.planYearsLatestFirst
    }

    get firstPlanYear() {
        return this.plan.firstPlanYear
    }

    eligibleTotalInPence() {
        return sumBy(this.invoiceLineItems, it => it.eligibleAmountInPence)
    }

    planYearBenefitSettlements() {
        const planYearBenefitEligibilityTotals = new PlanYearBenefitAmountTotals(this.invoiceLineItems.map(it => new PlanYearBenefitAmount({
            planYearId: it.planYearId,
            benefitType: it.benefitType,
            amountInPence: it.eligibleAmountInPence
        })))

        const claimApprovedCosts = this.claim().decision?.approvedCosts

        const summaries = this.planYears.flatMap(planYear => {
            return planYear.cashLimitedBenefits.flatMap(benefit => {
                const eligibleTotalInPence = planYearBenefitEligibilityTotals.getTotalFor(planYear.id, benefit.type)
                return (eligibleTotalInPence > 0) ? new PlanYearBenefitSettlement({
                    planYear,
                    benefit,
                    eligibleTotalInPence: eligibleTotalInPence,
                    approvedAmountInPence: claimApprovedCosts?.getApprovedAmountInPenceOrUndefinedFor(planYear.id, benefit.type)
                }) : [] as PlanYearBenefitSettlement[]
            })
        })

        return orderBy(summaries, [it => it.planYear?.start?.getTime(), it => it.getApplicableExcess() ? 0 : 1])
    }

    planYearExcessBenefitSettlements() {
        const NO_EXCESS = 'no-excess'
        const benefitSettlements = this.planYearBenefitSettlements()
        const settlementsByPlanYearExcess = groupByNested(benefitSettlements, it => [it.planYear, it.getApplicableExcess() ?? NO_EXCESS]) as Map<PlanYear, Map<ExcessWithUsage | 'no-excess', PlanYearBenefitSettlement[]>>

        const claimApprovedCosts = this.claim().decision?.approvedCosts

        const response = []
        for (const [planYear, benefitsByExcess] of settlementsByPlanYearExcess) {
            response.push(new PlanYearSettlement({
                excessGroups: [...benefitsByExcess.entries()].map(([maybeExcess, benefitSettlements]) => {
                    const excess = (maybeExcess !== NO_EXCESS) ? maybeExcess : undefined
                    return new PlanYearSettlementExcessGroup({
                        excess,
                        eligibleBenefitSettlements: benefitSettlements,
                        excessUsageInPence: claimApprovedCosts?.getExcessUsageInPenceFor(planYear.id, excess?.id)
                    })
                }),
                planYear
            }))
        }
        return response
    }

    private cannotApproveOrDeclineReasons() {
        const reasons = []
        isEmpty(this.allPossibleReasonsForSubmission()) && reasons.push(`At least one reason for the ${prettyPrintClaimStage(this.claim())} should be provided`)
        this.claim().isClaim() && isEmpty(this.invoiceLineItems) && reasons.push(`At least one invoice item should be provided`)
        this.claim().isCoverCheck() && isEmpty(this.requestedTreatments) && reasons.push(`At least one requested treatment should be provided`)
        return reasons
    }

    cannotApproveReasons() {
        const reasons = [...this.cannotApproveOrDeclineReasons()]
        if (this.claim().isClaim()) {
            this.eligibleTotalInPence() <= 0 && reasons.push('Eligible total must not be zero')
        }
        return reasons
    }

    cannotDeclineReasons() {
        const reasons = [...this.cannotApproveOrDeclineReasons()]
        return reasons
    }

    coverChecks() {
        return this.otherClaimActivities.filter(it => it.isCoverCheck())
    }

    allPossibleReasonsForSubmission() {
        return [...this.linkedReasonsForSubmission().map(it => asValidatable({...it, readOnly: true})), ...this.reasonsForClaimActivity]
    }

    miscWarnings() {
        const reasons = []
        const benefitsUnderAssessment = this.distinctPlanYearBenefitsUnderAssessment()
        const benefitTypesWithSuspiciousSubmissionDates = uniq(benefitsUnderAssessment.flatMap(it => this.submissionDateVerySoonAfterBenefitStarted(it) ? [it.type] : []))
        const benefitTypesWithOtherClaimsInProgress = uniq(benefitsUnderAssessment.flatMap(it => !isEmpty(this.otherPendingClaimsOnBenefit(it.type)) ? [it.type] : []))

        this.alsoClaimingOnAnotherPolicy() && reasons.push('Customer declared that they are also claiming on another policy')
        !isEmpty(benefitTypesWithOtherClaimsInProgress) && reasons.push(`One or more other claims are in progress for ${benefitTypesWithOtherClaimsInProgress.map(it => this.productConfigService.getBenefitDisplayName(it)).join(' and ')}`)
        !isEmpty(benefitTypesWithSuspiciousSubmissionDates) && reasons.push(`${prettyPrintClaimStage(this.claim())} was submitted very soon after cover started on ${benefitTypesWithSuspiciousSubmissionDates.map(it => this.productConfigService.getBenefitDisplayName(it)).join(' and ')}`)
        this.plan.life.isCancelled && reasons.push(`Customer was cancelled on ${ukStyleDate(this.plan.life.dateCancelled)}`)

        return reasons
    }

    isHighlighted(thing: HasId) {
        return this.highlighted.some(it => it.id === thing.id)
    }

    getAllBenefitTypesThatCanEverBeClaimed() {
        return this.productConfigService.getBenefitTypesWithAnyObligationToClaim()
    }
    
    getAllBenefitTypesThatCanEverBeCoverChecked() {
        return this.productConfigService.getBenefitTypesWithAnyObligationToCoverCheck()
    }

    private ifBenefitTypeIsEverClaimable(benefitType: BenefitType) {
        return this.getAllBenefitTypesThatCanEverBeClaimed().includes(benefitType) ? benefitType : undefined
    }
    
    private ifBenefitTypeIsEverCoverCheckable(benefitType: BenefitType) {
        return this.getAllBenefitTypesThatCanEverBeCoverChecked().includes(benefitType) ? benefitType : undefined
    }

    private planYearIsNotAsSuggestedFor(it: ValidatableClaimInvoiceLineItem) {
        return it.planYearId !== this.suggestedPlanYearFor(it)?.id
    }

    private isLateClaimSubmissionGiven(benefitType: BenefitType, dateSubmitted: Date, treatmentOrPaymentDate?: Date) {
        if (treatmentOrPaymentDate) {
            const lodgementWindowInWeeks = this.productConfigService.getClaimLodgementWindowInWeeksForBenefit(benefitType)
            const latestValidSubmissionDate = addWeeks(treatmentOrPaymentDate, lodgementWindowInWeeks)
            return !isBefore(dateSubmitted, latestValidSubmissionDate)            
        }
    }

    private onsetDateIsMandatoryFor(benefitType?: BenefitType) {
        //todo should move this logic to product config
        return benefitType && benefitType !== PeachyBenefitTypes.DENTAL && benefitType !== PeachyBenefitTypes.OPTICAL
    }

    private submissionDateVerySoonAfterBenefitStarted(benefit: Benefit) {
        //todo should move this logic to product config
        const NUM_DAYS_CONSIDERED_ACCEPTABLE_FOR_CLAIM_AFTER_COVER_START = 180
        const daysBeforeSubmission = differenceInDays(this.claim().dateSubmitted, benefit.startDate)
        return daysBeforeSubmission < NUM_DAYS_CONSIDERED_ACCEPTABLE_FOR_CLAIM_AFTER_COVER_START
    }

    private alsoClaimingOnAnotherPolicy() {
        return this.claim().isClaim() && (this.claimEnquiryReader as MakeClaimEnquiryReader)?.extractClaimingOnAnotherPolicyFrom?.(this.enquiry)
    }

    private otherPendingClaimsOnBenefit(benefitType: BenefitType) {
        const parentBenefitType = this.productConfigService.getParentTypeOf(benefitType)
        return this.otherClaimActivities.filter(it =>
            it.isClaim() &&
            it.isPendingDecision() && (
                (isEmpty(it.assessedBenefits) && [benefitType, parentBenefitType].includes(it.customerDeclaredBenefitType)) || 
                it.hasBeenAssessedAsRelatingTo(benefitType)
            )
        )
    }

    private linkedReasonsForSubmission() {
        return this.linkedCoverChecks().flatMap(it => it.assessment?.submissionReasons ?? [])
    }

    private distinctPlanYearBenefitsUnderAssessment() {
        const benefitsUnderAssessment = []
        if (this.claim().isClaim()) {
            benefitsUnderAssessment.push(...this.invoiceLineItems.map(it => this.plan.getBenefitInPlanYear(it.planYearId, it.benefitType)))
        } else if (this.claim().isCoverCheck()) {
            benefitsUnderAssessment.push(...this.requestedTreatments.map(it => this.plan.getBenefitInCurrentPlanYear(it.benefitType)))
        }
        return uniqBy(benefitsUnderAssessment.filter(it => !!it), it => `${it.planYearId}${it.type}`)
    }

    private highlightTemporarily(thing: HasId) {
        this.highlight(thing, 2)
    }

    private highlight(thing: HasId, forSeconds?: number) {
        this.highlightedApi.push(thing)
        if (forSeconds) {
            setTimeout(() => this.highlightedApi.removeById(thing.id), forSeconds * 1000)
        }
    }

    private scrollTo(sectionId: PageSectionId) {
        document.getElementById(sectionId).scrollIntoView({behavior: 'smooth'})
    }

    private spin() {
        this.setShowSpinner(true)
    }

    private stopSpin() {
        this.setShowSpinner(false)
    }

    private scrollToFormTop() {
        this.scrollTo('reasonCapture')
    }

    private optionallyScrollToAndHighlight(thing: HasId, sectionId: PageSectionId, action: boolean) {
        if (action) {
            this.scrollTo(sectionId)
            this.highlightTemporarily(thing)
        }
    }

    private scrollToAndHighlightFirstInvalid(items: Validatable<any>[], sectionId: PageSectionId) {
        const firstInvalid = items.find(it => it.hasErrors())
        if (firstInvalid) {
            this.optionallyScrollToAndHighlight(firstInvalid, sectionId, true)
        }
        return firstInvalid
    }

    //should replace with validation anotations --------------------

    get endOfToday() {
        //bit hacky sorry (all coz it's a bit hacky with how we capture days as mid day)
        return setHours(new Date(), 23)
    }

    get validatables() {
        return [
            {accessor: () => this.reasonsForClaimActivity, validator: (it: any) => this.validateReasonForClaimActivity(it)},
            {accessor: () => this.hospitalAdmissions, validator: (it: any) => this.validateHospitalAdmission(it)},
            {accessor: () => this.invoiceLineItems, validator: (it: any) => this.validateInvoiceLineItem(it)},
            {accessor: () => this.requestedTreatments, validator: (it: any) => this.validateRequestedTreatment(it)}
        ]
    }

    getAndShowAnyFieldErrors() {
        let errorsExist = false
        this.showAllFieldErrors()
        if (this.anyFieldErrorsExist()) {
            this.scrollToFormTop()
            errorsExist = true
        }
        return errorsExist
    }

    showAllFieldErrors() {
        this.validatables.forEach(validatable => {
            validatable.accessor().forEach(it => {
                it.visitAllFields()
                validatable.validator(it)
            })
        })
    }

    createEffectsToValidateAllValidatablesWhenTheyChange() {
        this.validatables.forEach(validatable => {
            createEffect(() => {
                validatable.accessor().forEach(it => validatable.validator(it))
            })
        })
    }

    validateInvoiceLineItem(it: ValidatableClaimInvoiceLineItem) {
        const endOfToday = this.endOfToday
        const linkedReason = this.allPossibleReasonsForSubmission().find(reason => reason.isValid() && reason?.id === it.reasonId)
        const mandatoryOnsetDate = this.onsetDateIsMandatoryFor(it.benefitType)
        const linkedBenefit = this.plan.getBenefitInPlanYear(it.planYearId, it.benefitType)
        const linkedAdmission = this.hospitalAdmissions.find(admission => admission.id === it.hospitalAdmissionId )
        const benefitNotCovered = !!it.benefitType && !linkedBenefit

        const procedureError = !it.procedure ? 'required' : undefined
        const treatmentDateError = !isValid(it.treatmentDate) || !isBefore(it.treatmentDate, endOfToday) ? 'past date required' : undefined
        const paymentDateError = !isValid(it.treatmentPaymentDate) || !isBefore(it.treatmentPaymentDate, endOfToday) ? 'past date required' : undefined
        const invoiceAmountInPenceError = isUndefined(it.invoiceAmountInPence) ? 'required' : !(isFinite(it.invoiceAmountInPence) && it.invoiceAmountInPence > 0) ? 'must not be zero' : undefined
        const eligibleAmountInPenceError = isUndefined(it.eligibleAmountInPence) ? 'required' : it.eligibleAmountInPence > it.invoiceAmountInPence ? 'exceeds invoice' : benefitNotCovered && it.eligibleAmountInPence > 0 ? 'benefit not covered' : undefined
        const benefitError = !it.benefitType ? 'required' : undefined
        const reasonError = !linkedReason ? 'required' : (mandatoryOnsetDate && !linkedReason.onsetDate) ? 'onset date required' : undefined

        it.setErrors({
            procedure: procedureError,
            reasonId: reasonError,
            treatmentDate: treatmentDateError,
            treatmentPaymentDate: paymentDateError,
            invoiceAmountInPence: invoiceAmountInPenceError,
            eligibleAmountInPence: eligibleAmountInPenceError,
            benefitType: benefitError
        })

        const ifBeforeBenefitStartedWarning = (givenDate: Optional<Date>, warning = 'before benefit started') => givenDate && linkedBenefit && !linkedBenefit.startedOnOrBefore(givenDate) ? warning : undefined
        const ifAfterBenefitCancelledWarning = (givenDate: Optional<Date>, warning = 'after benefit cancelled') => givenDate && linkedBenefit && linkedBenefit.wasCancelledBefore(givenDate) ? warning : undefined

        let reasonWarning = !reasonError && ifBeforeBenefitStartedWarning(linkedReason?.onsetDate, 'onset before benefit started')
        reasonWarning ??= !reasonError && ifAfterBenefitCancelledWarning(linkedReason?.onsetDate, 'onset after benefit cancelled')

        let treatmentDateWarning = !treatmentDateError && ifAfterBenefitCancelledWarning(it.treatmentDate)
        treatmentDateWarning ??= !treatmentDateError && ifBeforeBenefitStartedWarning(it.treatmentDate)
        treatmentDateWarning ??= !treatmentDateError && this.isLateClaimSubmissionGiven(it.benefitType, this.claim().dateSubmitted, it.treatmentDate) ? 'late submission' : undefined

        let paymentDateWarning = !paymentDateError && ifAfterBenefitCancelledWarning(it.treatmentPaymentDate)
        paymentDateWarning ??= !paymentDateError && ifBeforeBenefitStartedWarning(it.treatmentPaymentDate)
        paymentDateWarning ??= !paymentDateError && this.isLateClaimSubmissionGiven(it.benefitType, this.claim().dateSubmitted, it.treatmentPaymentDate) ? 'late submission' : undefined

        const hospitalAdmissionWarning = ifAfterBenefitCancelledWarning(linkedAdmission?.dischargeDate) ?? ifBeforeBenefitStartedWarning(linkedAdmission?.admissionDate)

        const planYearWarning = this.planYearIsNotAsSuggestedFor(it) ? 'not suggested' : undefined

        const benefitWarning = !benefitError && !linkedBenefit ? 'not covered' : undefined

        it.setWarnings({
            reasonId: reasonWarning,
            treatmentDate: treatmentDateWarning,
            treatmentPaymentDate: paymentDateWarning,
            hospitalAdmissionId: hospitalAdmissionWarning,
            planYearId: planYearWarning,
            benefitType: benefitWarning
        })
    }

    validateHospitalAdmission(it: ValidatableHospitalAdmission) {
        const endOfToday = this.endOfToday

        const admissionDateError = !isValid(it.admissionDate) || !isBefore(it.admissionDate, endOfToday) ? 'past date required' : undefined

        const dischargeDateError = !isValid(it.dischargeDate) || !isBefore(it.dischargeDate, endOfToday) ? 'past date required' :
            !admissionDateError && !(isAfter(it.dischargeDate, it.admissionDate)||isEqual(it.dischargeDate, it.admissionDate)) ? 'must be after admission date' :
            undefined

        it.setErrors({
            admissionDate: admissionDateError,
            dischargeDate: dischargeDateError
        })
    }

    validateReasonForClaimActivity(it: ValidatableClaimActivitySubmissionReason) {
        const endOfToday = this.endOfToday
        it.setErrors({
            symptoms: isEmpty(it.symptoms) ? 'required' : undefined,
            onsetDate: it.onsetDate && !isBefore(it.onsetDate, endOfToday) ? 'past date required' : undefined
        })
    }

    validateRequestedTreatment(it: ValidatableCoverCheckRequestedTreatment) {
        const linkedBenefit = this.plan.getBenefitInCurrentPlanYear(it.benefitType)
        const linkedReason = this.allPossibleReasonsForSubmission().find(reason => reason.isValid() && reason?.id === it.reasonId)
        const mandatoryOnsetDate = this.onsetDateIsMandatoryFor(it.benefitType)

        const procedureError = !it.procedure ? 'required' : undefined
        const benefitError = !it.benefitType ? 'required' : undefined
        const reasonError = !linkedReason ? 'required' : (mandatoryOnsetDate && !linkedReason.onsetDate) ? 'onset date required' : undefined

        it.setErrors({
            procedure: procedureError,
            benefitType: benefitError,
            reasonId: reasonError
        })

        const ifBeforeBenefitStartedWarning = (givenDate: Optional<Date>, warning: string) => givenDate && linkedBenefit && !linkedBenefit.startedOnOrBefore(givenDate) ? warning : undefined
        const ifAfterBenefitCancelledWarning = (givenDate: Optional<Date>, warning: string) => givenDate && linkedBenefit && linkedBenefit.wasCancelledBefore(givenDate) ? warning : undefined

        let reasonWarning = !reasonError && ifBeforeBenefitStartedWarning(linkedReason?.onsetDate, 'onset before benefit started')
        reasonWarning ??= !reasonError && ifAfterBenefitCancelledWarning(linkedReason?.onsetDate, 'onset after benefit cancelled')

        const benefitWarning = !benefitError && linkedBenefit?.hasBeenCancelled ? 'cancelled' : !benefitError && !linkedBenefit ? 'not covered' : undefined

        it.setWarnings({
            reasonId: reasonWarning,
            benefitType: benefitWarning
        })
    }

    private anyFieldErrorsExist() {
        return this.validatables.some(it =>
            it.accessor().some(item => item.hasErrors())
        )
    }
}

export class PlanYearSettlement {

    readonly planYear: PlanYear
    readonly excessGroups: PlanYearSettlementExcessGroup[]

    constructor(props: PropertiesOnly<PlanYearSettlement>) {
        assign(this, props)
    }

    getExcessGroupsOrderedBy(orderFn: (group: PlanYearSettlementExcessGroup) => unknown) {
        return sortBy(this.excessGroups, orderFn)
    }

    postExcessTotalInPence() {
        return sumBy(this.excessGroups, it => it.postExcessSettlementTotalInPence())
    }

    hasMultipleGroups() {
        return this.excessGroups.length > 1
    }
}

export class PlanYearSettlementExcessGroup {

    public static WithExcessFirst = (group: PlanYearSettlementExcessGroup) => group.excessApplies() ? 0 : 1

    readonly excess?: ExcessWithUsage
    readonly excessUsageInPence?: number
    readonly eligibleBenefitSettlements: PlanYearBenefitSettlement[]

    constructor(props: PropertiesOnly<PlanYearSettlementExcessGroup>) {
        assign(this, props)
    }

    preExcessSettlementTotalInPence() {
        return sumBy(this.eligibleBenefitSettlements, it => it.approvedAmountInPence ?? 0)
    }

    applicableExcessInPenceIfApprovalOf(givenApprovalAmountInPence: number) {
        // take into account excessAlreadyUsedDueToThisClaim in case we are editing an already approved claim.  if so then the already used excess is still available
        const excessAlreadyUsedDueToThisClaim = this.excessUsageInPence ?? 0
        return this.excessApplies() ? Math.min(this.excess.amountOutstandingInPence + excessAlreadyUsedDueToThisClaim, givenApprovalAmountInPence) : 0
    }

    postExcessSettlementTotalInPence() {
        return this.preExcessSettlementTotalInPence() - (this.excessUsageInPence ?? 0)
    }

    excessApplies() {
        return !!this.excess
    }

}

export class PlanYearBenefitSettlement {
    readonly planYear: PlanYear
    readonly benefit: BenefitWithUsage
    readonly eligibleTotalInPence: number
    readonly approvedAmountInPence?: number

    readonly id: string

    constructor(props: Pick<PlanYearBenefitSettlement, 'planYear' | 'benefit' | 'eligibleTotalInPence' | 'approvedAmountInPence'>) {
        this.planYear = props.planYear
        this.benefit = props.benefit
        this.eligibleTotalInPence = props.eligibleTotalInPence
        this.approvedAmountInPence = props.approvedAmountInPence

        // must be consistent and unique
        this.id = this.planYear.id + this.benefit.type
    }

    get absoluteBenefitRemainingInPence() {
        return this.benefit.limitRemaining
    }

    get eligibleTotalExceedsBenefitRemaining() {
        return this.absoluteBenefitRemainingInPence < this.eligibleTotalInPence
    }

    get maxApprovable() {
        return Math.min(this.eligibleTotalInPence, this.absoluteBenefitRemainingInPence)
    }

    isEligible () {
        return this.eligibleTotalInPence > 0
    }

    isPayable () {
        return this.absoluteBenefitRemainingInPence > 0 && this.isEligible()
    }

    getApplicableExcess() {
        return this.planYear.getExcessApplicableTo(this.benefit.type)
    }
}


type Errors<T> = Partial<Record<keyof T, string>>
type Warnings<T> = Errors<T>
export type Validatable<T> = T & {
    visit: (field: keyof T) => void
    isVisited: (field: keyof T, strict?: boolean) => boolean
    visitAllFields: () => void
    setErrors: SetStoreFunction<Errors<T>>
    getErrorIfVisitedFor: (field: keyof T) => string | undefined
    getErrorFor: (field: keyof T) => string | undefined
    hasErrors: () => boolean
    isValid: (field?: keyof T) => boolean

    setWarnings: SetStoreFunction<Warnings<T>>
    getWarningIfVisitedFor: (field: keyof T) => string | undefined
    getWarningFor: (field: keyof T) => string | undefined
}

export function asValidatable<T extends object>(thing: T): Validatable<T> {

    const ALL_FIELDS = '%ALL%'
    const [errorsStore, setErrors] = createStore<Errors<T>>({})
    const [warningsStore, setWarnings] = createStore<Warnings<T>>({})
    const [visitedFields, setVisitedFields] = createSignal<(keyof T | '%ALL%')[]>([])

    const isVisited = (field: keyof T, strict?: boolean) => visitedFields().includes(field) || (!strict && visitedFields().includes(ALL_FIELDS))

    const getErrorFor = (field: keyof T) => errorsStore[field]

    const getErrorIfVisitedFor = (field: keyof T) => isVisited(field) ? getErrorFor(field) : undefined

    const getWarningFor = (field: keyof T) => warningsStore[field]

    const getWarningIfVisitedFor = (field: keyof T) => isVisited(field) ? getWarningFor(field) : undefined

    const isValid = (field?: keyof T) => {
        if (field) {
             return !getErrorFor(field)
        }
        return isEmpty(errorsStore)
    }

    return {
        ...thing,
        visit(field: keyof T) {
            setVisitedFields(prev => [...prev, field])
        },
        isVisited,
        visitAllFields() {
            setVisitedFields([ALL_FIELDS])
        },
        getErrorIfVisitedFor,
        getErrorFor,
        hasErrors() {
            return !isValid()
        },
        isValid,
        setErrors,
        setWarnings,
        getWarningIfVisitedFor,
        getWarningFor
    }
}

export function asValidatableProxy<T extends object>(thing: T): Validatable<T> {
    const validatable = asValidatable({})
    return new Proxy(thing, {
        get(target: T, prop: string | symbol, receiver: any) {
            return Reflect.get(prop in validatable ? validatable : target, prop, receiver)
        }
    }) as Validatable<T>
}
