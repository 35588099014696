._169Hiq_list {
  border: 2px solid var(--peachy-pink);
  font-size: var(--font-size-small);
  border-radius: .5em;
  overflow-y: scroll;
}

._169Hiq_listItem {
  padding: .2em .5em;
}

._169Hiq_listItem:hover {
  background-color: var(--peachy-pink-light);
  cursor: pointer;
}

._169Hiq_selectedItem {
  color: #fff;
}

._169Hiq_selectedItem, ._169Hiq_selectedItem:hover {
  background-color: var(--peachy-pink);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7QUFPQTs7OztBQUlBOzs7OztBQUtBOzs7O0FBSUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9wYWdlcy9BZG1pbi9zdWJzY3JpcHRpb24vQ2FuY2VsTW9kYWwvc2VsZWN0b3IvQ2FuY2VsUmVhc29uLm1vZHVsZS5jc3MiXSwic291cmNlc0NvbnRlbnQiOlsiLmxpc3Qge1xuICAgIGJvcmRlcjogMnB4IHNvbGlkIHZhcigtLXBlYWNoeS1waW5rKTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjVlbTtcbiAgICBmb250LXNpemU6IHZhcigtLWZvbnQtc2l6ZS1zbWFsbCk7XG4gICAgb3ZlcmZsb3cteTogc2Nyb2xsO1xufVxuXG4ubGlzdEl0ZW0ge1xuICAgIHBhZGRpbmc6IDAuMmVtIDAuNWVtO1xufVxuXG4ubGlzdEl0ZW06aG92ZXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXBlYWNoeS1waW5rLWxpZ2h0KTtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi5zZWxlY3RlZEl0ZW0ge1xuICAgIGNvbG9yOiB3aGl0ZTtcbn1cblxuLnNlbGVjdGVkSXRlbSwgLnNlbGVjdGVkSXRlbTpob3ZlciB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGVhY2h5LXBpbmspO1xufVxuIl0sIm5hbWVzIjpbXX0= */