import {RepoClaimActivity} from '@peachy/repo-domain'
import {changeSet} from './peachyChangeSet'


export type v105_ota_6_aware_RepoClaimActivity = RepoClaimActivity & {
    benefitId: string,
    decision: RepoClaimActivity['decision'] & {
        /** @deprecated */
        amountInPence?: number
        /** @deprecated */
        assessment: {
            /** @deprecated */
            planYearId: string
            /** @deprecated */
            symptomsOnsetDate: string
            /** @deprecated */
            treatmentDate?: string
            /** @deprecated */
            treatmentPaymentDate?: string
            /** @deprecated */
            admissionDate?: string
            /** @deprecated */
            dischargeDate?: string
            /** @deprecated and yes, it WAS spelled incorrectly previously */
            referalDate?: string
        }
    }
}

export const changeSets = [
    changeSet({id: '001-set-plan-year-on-approved-claim-activities', author: 'dan.parker'}, async tx => {

        const claimActivities = await tx.claimActivities() as unknown as v105_ota_6_aware_RepoClaimActivity[]

        let mutatedAny = false
        for (const activity of claimActivities) {
            // all claim activities with a decision to date must have been for 2022_2023
            if (activity.decision) {
                activity.decision.assessment = activity.decision.assessment ?? ({} as v105_ota_6_aware_RepoClaimActivity['decision']['assessment'])
                if (!activity.decision.assessment.planYearId) {
                    activity.decision.assessment.planYearId = '2022_2023'
                    mutatedAny = true
                }
            }
        }

        if (mutatedAny) {
            await tx.claimActivities(claimActivities)
        }

    })

]
