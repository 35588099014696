import { peachyProductConfigService } from '@peachy/repo-domain'
import { LegacySubmitClaimsActivity, SubmitClaimsActivity, SubmitClaimsActivityResponse } from './IPeachyClient'
import { PeachyClient } from './PeachyClient'

export class LegacyPeachyClient extends PeachyClient {

  submitClaimsActivity(details: SubmitClaimsActivity) {
    const shouldRaiseTicket = peachyProductConfigService.shouldSubmitClaimActivityForAssessment(details.benefitType, details.stage)
    if (shouldRaiseTicket) {
        const legacyDetails: LegacySubmitClaimsActivity = {...details}
        return this.claimsApi.POST<SubmitClaimsActivityResponse>('/claim/submit', {body: legacyDetails})
    }
  }
}