import {changeLog} from './changelog'
import {FlashRepoMigrationRunner} from '@peachy/flash-repo-pure'
import {PeachyFlashRepo} from '../types'
import {ConsoleLogger, Logger} from '@peachy/utility-kit-pure'

export class PeachyFlashRepoMigrationRunner {

    private migrationRunner
    constructor(peachRepo: PeachyFlashRepo, logger: Logger = new ConsoleLogger()) {
        this.migrationRunner = new FlashRepoMigrationRunner(peachRepo, changeLog, logger)
    }

    public async run() {
        return this.migrationRunner.run()
    }

    public async forceSkipTheWholeChangeLog() {
        return this.migrationRunner.forceSkipTheWholeChangeLog()
    }
}
