import {
    AppointmentRepository,
    ClaimActivityRepository,
    EnquiryRepository,
    InProgressRepository,
    ManagedLifeRepository,
    NonManagedLifeRepository,
    PeachyFlashRepo,
    PlanRepository,
    PolicyRepository,
    PreferencesRepository
} from '@peachy/flash-repo-peachy-client'
import {
    AppointmentsService,
    AscentiService,
    BenefitsService,
    ClaimsSearchService,
    ClaimsService,
    EnquiryDefinition,
    EnquiryDefinitionService,
    EnquiryService,
    GetCareService,
    HealthHeroService,
    ImageCompressor,
    InProgressService,
    IPeachyClient,
    LifeService,
    NhsService,
    PhysioService,
    PlanService,
    PolicyService,
    PreferencesService,
    QuestionInitialisationData,
    RepoManagementService,
    SearchService,
    SupportDeskService,
    VirtualGpService
} from '@peachy/service'
import {RemoteRepoAdapter} from '@peachy/flash-repo-pure'
import {HealthHeroClient, PeachyProxiedHealthHeroClient} from '@peachy/health-hero-client'
import {AuthDetails, AuthProvider, ConsoleLogger, Logger, NupaHttpClient, Optional} from '@peachy/utility-kit-pure'
import {EnquiryQuestionDataModelProviders} from './enquiry/EnquiryQuestionDataModelProviders'
import {ExcessService} from './ExcessService'
import { IMediaUploadService } from './IMediaUploadService'
import {ProductConfigService} from '@peachy/repo-domain'
import { AddressLookupApi, AddressLookupApiDefinition } from '@punnet/address-lookup-pure'
import { makeApiGatewayClient } from '@peachy/core-domain-client'

//todo we should really import AppConfig to use, but that involves moving it out of @peachy/mobile-shared to somewhere available to @peachy/service
// all we really care about for now is the service patch config location to be able to build that prop, so this will do for now
type AppConfigPlaceholder = {
    EnvVars: { SERVICE_PATCH_URI: string }
}
export class ApplicationContextBeanContainer<AppConfigType extends AppConfigPlaceholder = any> {

    config: AppConfigType
    servicePatchUri: string
    logger: Logger
    peachyClient: IPeachyClient
    healthHeroClient: HealthHeroClient
    imageCompressor: ImageCompressor

    peachyFlashRepo: PeachyFlashRepo
    remoteRepoAdapter: RemoteRepoAdapter
    repoManagementService: RepoManagementService

    managedLifeRepository: ManagedLifeRepository
    nonManagedLifeRepository: NonManagedLifeRepository
    claimActivityRepository: ClaimActivityRepository
    planRepository: PlanRepository
    enquiryRepository: EnquiryRepository
    appointmentRepository: AppointmentRepository
    policyRepository: PolicyRepository
    preferencesRepository: PreferencesRepository
    inProgressRepository: InProgressRepository

    enquiryDefinitions: EnquiryDefinition[]
    enquiryQuestionDataModelProviders: EnquiryQuestionDataModelProviders

    searchService: SearchService
    getCareService: GetCareService
    lifeService: LifeService
    enquiryDefinitionService: EnquiryDefinitionService
    enquiryService: EnquiryService
    claimsService: ClaimsService
    claimsSearchService: ClaimsSearchService
    planService: PlanService
    benefitsService: BenefitsService
    excessService: ExcessService
    policyService: PolicyService
    preferencesService: PreferencesService
    inProgressService: InProgressService
    nhsService: NhsService
    ascentiService: AscentiService
    healthHeroService: HealthHeroService
    virtualGpService: VirtualGpService
    physioService: PhysioService
    appointmentsService: AppointmentsService
    addressLookupService: AddressLookupApi
    productConfigService: ProductConfigService
    supportDeskService: SupportDeskService
    mediaUploadService: Optional<IMediaUploadService>
}

export type BeanName = keyof ApplicationContextBeanContainer

type BeanProviders = {[key in BeanName]: BeanProvider<BeanName, ApplicationContextBeanContainer[key]>}

interface BeanProvider<N extends BeanName, T extends ApplicationContextBeanContainer[N]> {
    (build?: BeanProviders): T
}

type PartialBeanProviders = Partial<BeanProviders>
type MandatoryBeanOverrides = Pick<BeanProviders,
    'healthHeroClient'|
    'peachyFlashRepo'|
    'config'|
    'remoteRepoAdapter'|
    'imageCompressor'|
    'enquiryDefinitions'|
    'peachyClient'|
    'productConfigService' |
    'supportDeskService' |
    'mediaUploadService'
>

type BeanProviderOverrides = PartialBeanProviders & MandatoryBeanOverrides

export class ApplicationContextFactory {

    private static buildBeans(built: Partial<ApplicationContextBeanContainer>, overrides: BeanProviderOverrides, ...names: BeanName[]) {

        const build: BeanProviders = {

            // MUST BE SUPPLIED IN OVERRIDES ============================================================================
            config: () => getOrBuildAndCache('config', built, overrides, build, () => undefined),
            //todo-dp rename to drop the peachy part
            peachyFlashRepo: () => getOrBuildAndCache('peachyFlashRepo', built, overrides, build, () => undefined),
            healthHeroClient: () => getOrBuildAndCache('healthHeroClient', built, overrides, build, () => undefined),
            imageCompressor: () => getOrBuildAndCache('imageCompressor', built, overrides, build, () => undefined),
            remoteRepoAdapter: () => getOrBuildAndCache('remoteRepoAdapter', built, overrides, build, () => undefined),
            enquiryDefinitions: () => getOrBuildAndCache('enquiryDefinitions', built, overrides, build, () => []),
            //todo-dp rename to drop the peachy part
            peachyClient: () => getOrBuildAndCache('peachyClient', built, overrides, build, () => undefined),
            productConfigService: () => getOrBuildAndCache('productConfigService', built, overrides, build, () => undefined),
            supportDeskService: () => getOrBuildAndCache('supportDeskService', built, overrides, build, () => undefined),
            mediaUploadService: () => getOrBuildAndCache('mediaUploadService', built, overrides, build, () => undefined),

            // MISC ============================================================================
            logger: () => {
                return getOrBuildAndCache('logger', built, overrides, build, () => new ConsoleLogger())
            },
            servicePatchUri: () => {
                return getOrBuildAndCache('servicePatchUri', built, overrides, build, () => build.config().EnvVars.SERVICE_PATCH_URI )
            },

            // REPOSITORIES ============================================================================
            inProgressRepository: () => {
                return getOrBuildAndCache('inProgressRepository', built, overrides, build, () => new InProgressRepository(
                     build.peachyFlashRepo()
                ))
            },
            planRepository: () => {
                return getOrBuildAndCache('planRepository', built, overrides, build, () => new PlanRepository(
                     build.peachyFlashRepo()
                ))
            },
            enquiryRepository: () => {
                return getOrBuildAndCache('enquiryRepository', built, overrides, build, () => new EnquiryRepository(
                     build.peachyFlashRepo()
                ))
            },
            claimActivityRepository: () => {
                return getOrBuildAndCache('claimActivityRepository', built, overrides, build, () => new ClaimActivityRepository(
                     build.peachyFlashRepo()
                ))
            },
            managedLifeRepository: () => {
                return getOrBuildAndCache('managedLifeRepository', built, overrides, build, () => new ManagedLifeRepository(
                     build.peachyFlashRepo()
                ))
            },
            nonManagedLifeRepository: () => {
                return getOrBuildAndCache('nonManagedLifeRepository', built, overrides, build, () => new NonManagedLifeRepository(
                     build.peachyFlashRepo()
                ))
            },
            policyRepository: () => {
                return getOrBuildAndCache('policyRepository', built, overrides, build, () => new PolicyRepository(
                     build.peachyFlashRepo()
                ))
            },
            appointmentRepository: () => {
                return getOrBuildAndCache('appointmentRepository', built, overrides, build, () => new AppointmentRepository(
                     build.peachyFlashRepo()
                ))
            },
            preferencesRepository: () => {
                return getOrBuildAndCache('preferencesRepository', built, overrides, build, () => new PreferencesRepository(
                     build.peachyFlashRepo()
                ))
            },


            // SERVICES ============================================================================
            enquiryQuestionDataModelProviders: () => {
                return getOrBuildAndCache('enquiryQuestionDataModelProviders', built, overrides, build, () => new EnquiryQuestionDataModelProviders(
                    build.planService(),
                    build.managedLifeRepository()
                ))
            },
            lifeService: () => {
                return getOrBuildAndCache('lifeService', built, overrides, build, () => new LifeService(
                     build.managedLifeRepository()
                ))
            },
            benefitsService: () => {
                return getOrBuildAndCache('benefitsService', built, overrides, build, () => new BenefitsService(
                     build.peachyFlashRepo(),
                     build.planRepository()
                ))
            },
            excessService: () => {
                return getOrBuildAndCache('excessService', built, overrides, build, () => new ExcessService())
            },
            claimsSearchService: () => {
                return getOrBuildAndCache('claimsSearchService', built, overrides, build, () => new ClaimsSearchService(
                     build.claimActivityRepository(),
                     build.lifeService()
                ))
            },
            planService: () => {
                return getOrBuildAndCache('planService', built, overrides, build, () => new PlanService(
                     build.logger(),
                     build.planRepository(),
                     build.peachyFlashRepo(),
                     build.managedLifeRepository(),
                     build.claimsSearchService(),
                     build.benefitsService(),
                     build.excessService(),
                     build.productConfigService()
                ))
            },
            enquiryDefinitionService: () => {
                return getOrBuildAndCache('enquiryDefinitionService', built, overrides, build, () => new EnquiryDefinitionService(
                     build.enquiryDefinitions()
                ))
            },
            enquiryService: () => {
                return getOrBuildAndCache('enquiryService', built, overrides, build, () => new EnquiryService(
                     build.enquiryRepository(),
                     build.config() as QuestionInitialisationData['config'],
                     build.enquiryDefinitionService()
                ))
            },
            inProgressService: () => {
                return getOrBuildAndCache('inProgressService', built, overrides, build, () => new InProgressService(
                    build.logger(),
                    build.peachyFlashRepo(),
                    build.inProgressRepository(),
                    build.claimActivityRepository(),
                    build.appointmentRepository(),
                    build.enquiryService()
                ))
            },
            policyService: () => {
                return getOrBuildAndCache('policyService', built, overrides, build, () => new PolicyService(
                     build.policyRepository(),
                     build.planService(),
                     build.nonManagedLifeRepository(),
                     build.planRepository(),
                     build.managedLifeRepository(),
                     build.peachyClient()
                ))
            },
            claimsService: () => {
                return getOrBuildAndCache('claimsService', built, overrides, build, () => new ClaimsService(
                     build.logger(),
                     build.peachyClient(),
                     build.peachyFlashRepo(),
                     build.claimActivityRepository(),
                     build.inProgressService(),
                     build.enquiryService(),
                     build.lifeService(),
                     build.policyService(),
                     build.repoManagementService(),
                     build.productConfigService()
                ))
            },
            searchService: () => {
                return getOrBuildAndCache('searchService', built, overrides, build, () => new SearchService())
            },
            getCareService: () => {
                return getOrBuildAndCache('getCareService', built, overrides, build, () => new GetCareService(
                     build.peachyClient(),
                     build.searchService()
                ))
            },
            preferencesService: () => {
                return getOrBuildAndCache('preferencesService', built, overrides, build, () => new PreferencesService(
                     build.preferencesRepository()
                ))
            },
            nhsService: () => {
                return getOrBuildAndCache('nhsService', built, overrides, build, () => new NhsService(
                     build.peachyClient()
                ))
            },
            ascentiService: () => {
                return getOrBuildAndCache('ascentiService', built, overrides, build, () => new AscentiService(
                     build.peachyClient()
                ))
            },
            healthHeroService: () => {
                return getOrBuildAndCache('healthHeroService', built, overrides, build, () => new HealthHeroService(
                     build.logger(),
                     build.healthHeroClient(),
                     build.imageCompressor()
                ))
            },
            virtualGpService: () => {
                return getOrBuildAndCache('virtualGpService', built, overrides, build, () => new VirtualGpService(
                     build.lifeService(),
                     build.addressLookupService(),
                     build.enquiryService(),
                     build.enquiryDefinitionService(),
                     build.healthHeroService(),
                ))
            },
            physioService: () => {
                return getOrBuildAndCache('physioService', built, overrides, build, () => new PhysioService(
                     build.logger(),
                     build.ascentiService(),
                     build.lifeService(),
                     build.enquiryService(),
                     build.enquiryDefinitionService(),
                ))
            },
            appointmentsService: () => {
                return getOrBuildAndCache('appointmentsService', built, overrides, build, () => new AppointmentsService(
                     build.logger(),
                     build.peachyFlashRepo(),
                     build.appointmentRepository(),
                     build.enquiryService(),
                     build.inProgressService(),
                     build.lifeService(),
                     build.policyService(),
                     build.repoManagementService(),
                     build.virtualGpService(),
                     build.physioService()
                ))
            },
            addressLookupService: () => {
                return getOrBuildAndCache('addressLookupService', built, overrides, build, () => {
                    const {auth, api} = build.peachyClient()
                    return makeApiGatewayClient(AddressLookupApiDefinition, api, auth, build.servicePatchUri())
                })
            },
            repoManagementService: () => {
                return getOrBuildAndCache('repoManagementService', built, overrides, build, () => new RepoManagementService(
                     build.logger(),
                     build.peachyFlashRepo(),
                     build.remoteRepoAdapter(),
                     build.inProgressService()
                ))
            }
        }

        const beans: Partial<ApplicationContextBeanContainer> = {}
        for (const beanName of names) {
            // @ts-ignore
            beans[beanName] = build[beanName]()
        }
        return beans
    }

    public static buildOnDemandFactory(beanOverrides: BeanProviderOverrides): BeanFactory {
        const cache: Partial<ApplicationContextBeanContainer> = {}
        return {
            getBean<N extends BeanName>(name: N) {
                const beans = (ApplicationContextFactory.buildBeans(cache, beanOverrides, name))
                return beans[name] as ApplicationContextBeanContainer[N]
            }
        }
    }
}

export interface BeanFactory {
    getBean<N extends BeanName>(beanName: N): ApplicationContextBeanContainer[N]
}

export interface ApplicationContext extends BeanFactory {
    init(config: object, logger: Logger, ownerId?: string): Promise<BeanFactory>
    destroy(): void
    isInitiated(): boolean
}

function getOrBuildAndCache<T>(beanName: BeanName, built: Partial<ApplicationContextBeanContainer>, overriddenBeanProviders: BeanProviderOverrides, dependencyProviders: BeanProviders, newUp: () => T): T {
    const alreadyBuilt = built[beanName]
    if (alreadyBuilt) {
        // @ts-ignore
        return alreadyBuilt
    }
    const overriddenBuild = overriddenBeanProviders[beanName]?.(dependencyProviders)
    if (overriddenBuild) {
        console.log('[ApplicationContext] building new', beanName, 'from overridden builder')
        // @ts-ignore
        built[beanName] = overriddenBuild
        // @ts-ignore
        return overriddenBuild
    }
    console.log('[ApplicationContext] building new', beanName)
    // @ts-ignore
    built[beanName] = newUp()
    // @ts-ignore
    return built[beanName]
}

type BuildHealthHeroClientConfig = {
    domain: string
    clientId: string
    proxy: string
    accessTokenProvider: () => Promise<string>
    requestStamp?: string
}

export function buildHealthHeroClient({domain, clientId, proxy, accessTokenProvider, requestStamp}: BuildHealthHeroClientConfig) {

    const debug = false

    const httpClient = new NupaHttpClient().withTimeout(31000).withDebugging(debug)
    const authProvider: Pick<AuthProvider, 'fetchAuth'> = {
        async fetchAuth() {
            const access_token = await accessTokenProvider()
            return AuthDetails.fromOidcAuthResponse({access_token, token_type: 'Bearer'})
        }
    }

    return new PeachyProxiedHealthHeroClient(domain, clientId, proxy, httpClient, authProvider)
    .withCommonClientAttributesProvidedBy(method => ['POST', 'DELETE'].includes(method) ? {[`${method}_SOURCE`]: requestStamp} : undefined)
    .withDebugging(debug)
}
