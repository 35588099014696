import {changeSet} from './peachyChangeSet'

export const changeSets = [
    
    changeSet({id: '001-remove-in-progress-claim-activities', author: 'dan.parker'}, async tx => {
        const {CLAIM: _omit, COVER_CHECK: _alsoOmit, ...otherInProgress} = await tx.inProgress()
        await tx.inProgress(otherInProgress)
    })

]
