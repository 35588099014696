._0FODUW_AdminResetPassword {
  flex-direction: column;
  align-items: center;
  gap: 5em;
  display: flex;
}

._0FODUW_AdminResetPassword fieldset {
  flex-direction: column;
  gap: 1em;
  width: 15em;
  display: inline-flex;
}

._0FODUW_AdminResetPassword fieldset button {
  width: 100%;
}

._0FODUW_toggle span {
  padding-left: .5em;
  font-size: .8em;
}

._0FODUW_toggle > input {
  width: 1.5em;
  height: 1em;
  background-color: var(--pencil-color) !important;
}

._0FODUW_toggle > input:before {
  background-color: #fff;
}

._0FODUW_toggle > input:checked {
  background-color: var(--peachy-green) !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7QUFRQTs7Ozs7OztBQU9BOzs7O0FBS0E7Ozs7O0FBS0E7Ozs7OztBQU9BOzs7O0FBSUEiLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3NyYy9wYWdlcy9BZG1pbi9wYXNzd29yZC9OZXdQYXNzd29yZC5tb2R1bGUuY3NzIl0sInNvdXJjZXNDb250ZW50IjpbIi5BZG1pblJlc2V0UGFzc3dvcmQge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGdhcDogNWVtO1xufVxuXG5cbi5BZG1pblJlc2V0UGFzc3dvcmQgZmllbGRzZXQge1xuICAgIGRpc3BsYXk6IGlubGluZS1mbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgd2lkdGg6IDE1ZW07XG4gICAgZ2FwOiAxZW07XG59XG5cbi5BZG1pblJlc2V0UGFzc3dvcmQgZmllbGRzZXQgYnV0dG9uIHtcbiAgICB3aWR0aDogMTAwJTtcbn1cblxuXG4udG9nZ2xlIHNwYW4ge1xuICAgIGZvbnQtc2l6ZTogMC44ZW07XG4gICAgcGFkZGluZy1sZWZ0OiAwLjVlbTtcbn1cblxuLnRvZ2dsZSA+IGlucHV0IHtcbiAgICBoZWlnaHQ6IDFlbTtcbiAgICB3aWR0aDogMS41ZW07XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tcGVuY2lsLWNvbG9yKSAhaW1wb3J0YW50O1xufVxuXG5cbi50b2dnbGUgPiBpbnB1dDo6YmVmb3JlIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB3aGl0ZTtcbn1cblxuLnRvZ2dsZSA+IGlucHV0OmNoZWNrZWQge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXBlYWNoeS1ncmVlbikgIWltcG9ydGFudDtcbn1cblxuIl0sIm5hbWVzIjpbXX0= */