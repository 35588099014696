.IKKc5q_details {
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  grid-template-columns: repeat(2, minmax(0, auto));
  min-width: 800px;
  display: grid;
}

.IKKc5q_wideField {
  grid-column: span 2;
}

.IKKc5q_container {
  flex-direction: column;
  row-gap: 2em;
  display: flex;
}

.IKKc5q_container > button {
  align-self: center;
  padding: .5em 1em;
}

.IKKc5q_table :where(th, td) {
  vertical-align: middle;
  padding: .5rem;
  font-size: medium;
  position: relative;
}

.IKKc5q_table a {
  color: unset;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjpudWxsLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7O0FBUUE7Ozs7QUFJQTs7Ozs7O0FBTUE7Ozs7O0FBS0E7Ozs7Ozs7QUFPQSIsInNvdXJjZXMiOlsiLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vLi4vc3JjL3BhZ2VzL0FkbWluL3N1YnNjcmlwdGlvbi9DYW5jZWxTdWJzY3JpcHRpb24ubW9kdWxlLmNzcyJdLCJzb3VyY2VzQ29udGVudCI6WyIuZGV0YWlscyB7XG4gICAgbWluLXdpZHRoOiA4MDBweDtcbiAgICBkaXNwbGF5OiBncmlkO1xuICAgIGdyaWQtdGVtcGxhdGUtY29sdW1uczogcmVwZWF0KDIsIG1pbm1heCgwLCBhdXRvKSk7XG4gICAgZ3JpZC1jb2x1bW4tZ2FwOiAxZW07XG4gICAgZ3JpZC1yb3ctZ2FwOiAxZW07XG59XG5cbi53aWRlRmllbGQge1xuICAgIGdyaWQtY29sdW1uOiBzcGFuIDI7XG59XG5cbi5jb250YWluZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICByb3ctZ2FwOiAyZW07XG59XG5cbi5jb250YWluZXI+YnV0dG9uIHtcbiAgICBwYWRkaW5nOiAwLjVlbSAxZW07XG4gICAgYWxpZ24tc2VsZjogY2VudGVyO1xufVxuXG4udGFibGUgOndoZXJlKHRoLCB0ZCkge1xuICAgIHBhZGRpbmc6IDAuNXJlbTtcbiAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgdmVydGljYWwtYWxpZ246IG1pZGRsZTtcbiAgICBmb250LXNpemU6IG1lZGl1bTtcbn1cblxuLnRhYmxlIGEge1xuICAgIGNvbG9yOiB1bnNldDtcbn0iXSwibmFtZXMiOltdfQ== */