import {PNode} from './path-builder/path-builder-domain'
import {Bookmark, BranchId, EmptyObjectHash, Flash, Flashlet, FlashObject, FlashPath, Hash} from './flash-repo-domain'
import {FlashStore} from './FlashStore'
import {PNodeProxy} from './path-builder/PNodeProxy'
import FlashPathHandler from './FlashPathHandler'
import {mergeObjects} from './flash-merge'
import {asDoc, ConsoleLogger, dump, Logger} from '@peachy/utility-kit-pure'


export interface IFlashRepo<_RootT extends object = object> {
    readonly repoId: string

    getContentRoot<T extends object = {}>(branchId?: BranchId): Promise<PNode<T>>
    getNode(path: FlashPath, branchId?: BranchId): Promise<PNode<any>>
    getContentRootHash(branchId?: BranchId): Promise<Hash>

    getBranch(): Promise<Branch>

    getBranchRootHash(branchId?: BranchId): Hash

    getBranchRootHashHistory(branchId?: BranchId): Hash[]

    sync(remoteRepo: RemoteRepoAdapter, branchId: BranchId): Promise<SyncContext>

    sank(syncRequest: SyncRequest): Promise<SyncResponse>

    dispose(): Promise<void>
}


export type RepoId = string

type MergeBias = 'left' | 'right'


function makeBranchZero(): Branch {
    return {
        rootHash: EmptyObjectHash,
        syncState: {}
    }
}

function makeSyncStateZero(): SyncState {
    return {
        thisBookmark: 0,
        thisRootHash: EmptyObjectHash,
        commonHash: EmptyObjectHash,
        thatRootHash: EmptyObjectHash,
        thatBookmark: 0,
    }
}

export type SyncContext = {
    _remoteRepoId: RepoId
    _branchId: BranchId

    a_preSyncBranchHash: Hash
    a_preSyncBranch0: Branch
    a_preSyncState: SyncState

    b_syncRequest?: SyncRequest
    c_syncResponse?: SyncResponse

    d_advisedSyncState?: SyncState

    e_preCommitBranchHash?: Hash
    e_preCommitBranch0?: Branch
    e_preCommitState?: SyncState

    f_postSyncBranchHash?: Hash
    f_postSyncBranch0?: Branch
    f_postSyncState?: SyncState
}


export interface RemoteRepoAdapter {
    remoteRepoId: string

    syncRepo(syncRequest: SyncRequest): Promise<SyncResponse>
}


export class Branch {
    rootHash: Hash
    syncState: {
        [remoteRepoId: RepoId]: SyncState
    }
}


export type SyncState = {
    thisBookmark: number,
    thisRootHash: Hash
    commonHash: Hash,
    thatRootHash: Hash
    thatBookmark: number,
}


export type SyncRequest = {
    repoId: string
    branchId: BranchId,
    rootHash: Hash,
    bookmark: number,
    syncState: SyncState
    flashlets: Flashlet[]
}


export type SyncResponse = {
    rootHash: Hash
    commonHash: Hash
    bookmark: Bookmark
    flashlets: Flashlet[]
}


export class FlashRepo<RootType extends object = object> implements IFlashRepo<RootType> {

    private contentRootNodes: Map<BranchId, PNode<any>> = new Map()
    private branchRootHashCache: Map<BranchId, Hash> = new Map()

    private syncing = false

    private synchronised = false

    isSyncing() {
        return this.syncing
    }

    isSynchronised() {
        this.logger.debug('In sync?', this.synchronised)
        return this.synchronised
    }


    constructor(
        public readonly repoId: RepoId,
        public readonly defaultBranchId: BranchId,
        public flashStore: FlashStore,
        private logger: Logger = new ConsoleLogger()
    ) {
    }

    async getContentRootHash(branchId: BranchId = this.defaultBranchId): Promise<Hash> {
        const branchRootHash = this.flashStore.getBranchRootHash(branchId)
        const branch: any = branchRootHash === EmptyObjectHash
            ? makeBranchZero()
            : await this.flashStore.build<Branch>(branchRootHash)
        return branch.rootHash
    }

    async getContentRoot<T = RootType>(branchId: BranchId = this.defaultBranchId): Promise<PNode<T>> {

        let contentRootNode = this.contentRootNodes.get(branchId)

        if (!contentRootNode) {

            const initialBranchRootHash = this.flashStore.getBranchRootHash(branchId)
            this.branchRootHashCache.set(branchId, initialBranchRootHash)

            const initialContentRootHash = await this.getContentRootHash(branchId)

            const flashPathHandler = new FlashPathHandler(
                this.flashStore,
                initialContentRootHash,
                async (newRootHash: Hash, oldRootHash: Hash) => {

                    this.logger.debug(`[node-set start]`)

                    const lastBranchHash = this.branchRootHashCache.get(branchId)
                    const currentBranchRootHash = this.flashStore.getBranchRootHash(branchId)

                    if (lastBranchHash && currentBranchRootHash
                        && (currentBranchRootHash !== lastBranchHash)) {

                        this.logger.debug('BOOM!', {
                            handlerRoot: flashPathHandler.getRootHash(),
                            lastBranchHash,
                            currentBranchRootHash: currentBranchRootHash
                        })


                        throw asDoc({
                            message: 'Stale update on commitNewRootHash',
                            old: lastBranchHash,
                            new: currentBranchRootHash
                        })
                    }

                    const currentBranch: Branch = currentBranchRootHash === EmptyObjectHash
                        ? makeBranchZero()
                        : await this.flashStore.build<Branch>(currentBranchRootHash)

                    const newBranchState: Branch = {
                        rootHash: newRootHash,
                        syncState: currentBranch.syncState,
                    }

                    const newBranchRootHash = await this.flashStore.put(newBranchState)
                    const haveCommitted = await this.flashStore.pushBranchRootHash(branchId, newBranchRootHash, lastBranchHash)

                    if (haveCommitted) {
                        this.logger.debug(`[node-set end] (contentRootHash: ${newRootHash}, branchRootHash: ${newBranchRootHash}) --> ${this.repoId}`)
                        this.branchRootHashCache.set(branchId, newBranchRootHash)
                    } else {
                        this.logger.debug(`[node-set end] ()`)
                    }
                }
            )
            contentRootNode = PNodeProxy.createRootProxy(flashPathHandler)
            contentRootNode.$(v => {
                this.synchronised = false
            })

            this.contentRootNodes.set(branchId, contentRootNode)
        }

        return contentRootNode as PNode<T>
    }

    async getNode(path: FlashPath, branchId: BranchId = this.defaultBranchId): Promise<PNode<any>> {
        let node = await this.getContentRoot<any>()
        for (const step of path) {
            // @ts-ignore
            node = node[step]
        }
        return node
    }

    async getBranch(branchId: BranchId = this.defaultBranchId): Promise<Branch> {
        const branchRootHash = this.getBranchRootHash(branchId)
        return this.flashStore.build<Branch>(branchRootHash)
    }

    getBranchRootHash(branchId: Hash = this.defaultBranchId): Hash {
        return this.flashStore.getBranchRootHash(branchId)
    }

    getBranchRootHashHistory(branchId: BranchId = this.defaultBranchId): Hash[] {
        return this.flashStore.getBranchHistory(branchId)
    }


    async merge(leftHash: Hash, centerHash: Hash, rightHash: Hash, mergeBias: MergeBias = 'left') {
        const left = await this.flashStore.get(leftHash) as FlashObject
        const right = await this.flashStore.get(rightHash) as FlashObject
        const center = await this.flashStore.get(centerHash) as FlashObject
        return mergeObjects(left, right, center, this.flashStore)
    }


    async sync(remoteRepo: RemoteRepoAdapter, branchId: BranchId = this.defaultBranchId): Promise<SyncContext> {
        return this.whileSyncing(async () => {
            this.logger.debug('[sync start]')
            const syncContext = await this.fetch(remoteRepo, branchId)
            const haveSynced = await this.commitSync(syncContext)
            // dumpContext(syncContext)
            this.logger.debug('[sync end]', haveSynced)
            this.synchronised = syncContext.f_postSyncState.thisRootHash === syncContext.f_postSyncState.thatRootHash


            this.logger.debug('Synchronised', this.synchronised)


            return haveSynced ? syncContext : null
        })
    }


    private async whileSyncing<T>(f: () => Promise<T>): Promise<T> {
        if (this.isSyncing()) {
            return null
        } else {
            try {
                this.syncing = true
                return await f()
            } finally {
                this.syncing = false
            }
        }
    }


    async fetch(remoteRepo: RemoteRepoAdapter, branchId: BranchId = this.defaultBranchId): Promise<SyncContext> {

        const preSyncBranchHash = this.flashStore.getBranchRootHash(branchId)
        const preSyncBranch: Branch = preSyncBranchHash === EmptyObjectHash
            ? makeBranchZero()
            : await this.flashStore.build<Branch>(preSyncBranchHash)


        const syncContext: SyncContext = {
            _branchId: branchId,
            _remoteRepoId: remoteRepo.remoteRepoId,
            a_preSyncBranchHash: preSyncBranchHash,
            a_preSyncBranch0: preSyncBranch,
            a_preSyncState: preSyncBranch.syncState?.[remoteRepo.remoteRepoId] ?? makeSyncStateZero(),
        }

        const hasOutgoingDelta = syncContext.a_preSyncBranch0.rootHash !== syncContext.a_preSyncState.commonHash

        syncContext.b_syncRequest = {
            repoId: this.repoId,
            branchId: syncContext._branchId,
            syncState: syncContext.a_preSyncState,
            rootHash: syncContext.a_preSyncBranch0.rootHash,
            bookmark: await this.flashStore.getBookmark(),
            flashlets: hasOutgoingDelta ? await this.flashStore.getSlice(syncContext.a_preSyncState.thisBookmark) : []
        }

        if (hasOutgoingDelta) {
            this.logger.debug(`[preSync] ${this.repoId} --> ${remoteRepo.remoteRepoId}`)
        } else {
            this.logger.debug(`[preSync ?] ${this.repoId} <-- ${remoteRepo.remoteRepoId}`)
        }


        syncContext.c_syncResponse = await remoteRepo.syncRepo(syncContext.b_syncRequest)


        const hasIncomingDelta = syncContext.c_syncResponse.rootHash != syncContext.c_syncResponse.commonHash

        if (hasIncomingDelta) {

            if (hasOutgoingDelta) {
                this.logger.debug(`[postSync] ${this.repoId} <-> ${remoteRepo.remoteRepoId}`)

                syncContext.d_advisedSyncState = {
                    commonHash: syncContext.c_syncResponse.commonHash,
                    thatBookmark: syncContext.c_syncResponse.bookmark,
                    thatRootHash: syncContext.c_syncResponse.rootHash,
                    thisBookmark: syncContext.b_syncRequest.bookmark,
                    thisRootHash: preSyncBranch.rootHash
                }

            } else {
                this.logger.debug(`[postSync] ${this.repoId} <-- ${remoteRepo.remoteRepoId}`)

                syncContext.d_advisedSyncState = {
                    commonHash: syncContext.c_syncResponse.rootHash,
                    thatBookmark: syncContext.c_syncResponse.bookmark,
                    thatRootHash: syncContext.c_syncResponse.rootHash,
                    thisBookmark: syncContext.a_preSyncState.thisBookmark,
                    thisRootHash: syncContext.c_syncResponse.rootHash
                }
            }
        } else {

            const havePushedDelta = syncContext.c_syncResponse.commonHash === syncContext.b_syncRequest.rootHash

            if (hasOutgoingDelta && havePushedDelta) {
                this.logger.debug(`[postSync] ${this.repoId} --> ${remoteRepo.remoteRepoId}`)

                syncContext.d_advisedSyncState = {
                    commonHash: syncContext.c_syncResponse.commonHash,
                    thatBookmark: syncContext.c_syncResponse.bookmark,
                    thatRootHash: syncContext.c_syncResponse.rootHash,
                    thisBookmark: syncContext.b_syncRequest.bookmark,
                    thisRootHash: preSyncBranch.rootHash
                }

            } else {
                this.logger.debug(`[postSync] ${this.repoId} === ${remoteRepo.remoteRepoId}`)
                syncContext.d_advisedSyncState = syncContext.a_preSyncState
            }
        }

        return syncContext
    }


    async sank(syncRequest: SyncRequest): Promise<SyncResponse> {
        return this.whileSyncing(async () => {

            this.logger.debug('[sank start]')

            // dumpReqRes(syncRequest, 'syncRequest')

            const preSyncBookmark = await this.flashStore.getBookmark()

            const preSyncBranchHash = this.flashStore.getBranchRootHash(syncRequest.branchId)
            const preSyncBranch: Branch = preSyncBranchHash === EmptyObjectHash
                ? makeBranchZero()
                : await this.flashStore.build<Branch>(preSyncBranchHash)


            const hasIncomingDelta = syncRequest.rootHash !== syncRequest.syncState.commonHash
            const hasOutgoingDelta = syncRequest.syncState.commonHash !== preSyncBranch.rootHash


            let syncResponse: SyncResponse
            let postSyncState: SyncState

            if (hasOutgoingDelta) {

                if (hasIncomingDelta) {
                    this.logger.debug(`[sank] ${syncRequest.repoId} <-> ${this.repoId}`)
                } else {
                    this.logger.debug(`[sank] ${syncRequest.repoId} <-- ${this.repoId}`)
                }

                const outgoingFlashlets = await this.flashStore.getSlice(syncRequest.syncState.thatBookmark)
                const postSyncBookmark = await this.flashStore.putSlice(syncRequest.flashlets)

                syncResponse = {
                    bookmark: postSyncBookmark,
                    commonHash: syncRequest.syncState.commonHash,
                    flashlets: outgoingFlashlets,
                    rootHash: preSyncBranch.rootHash
                }

            } else {

                if (hasIncomingDelta) {
                    this.logger.debug(`[sank] ${syncRequest.repoId} --> ${this.repoId}`)

                    const postSyncBookmark = await this.flashStore.putSlice(syncRequest.flashlets)

                    postSyncState = {
                        commonHash: syncRequest.rootHash,
                        thatBookmark: syncRequest.bookmark,
                        thatRootHash: syncRequest.rootHash,
                        thisBookmark: postSyncBookmark,
                        thisRootHash: syncRequest.rootHash
                    }

                    const postSyncBranch: Branch = {
                        rootHash: syncRequest.rootHash,
                        syncState: {
                            ...preSyncBranch.syncState,
                            [syncRequest.repoId]: postSyncState
                        }
                    }

                    const postSyncBranchHash = await this.flashStore.put(postSyncBranch)

                    try {

                        await this.flashStore.pushBranchRootHash(syncRequest.branchId, postSyncBranchHash, preSyncBranchHash)

                        this.branchRootHashCache.set(syncRequest.branchId, postSyncBranchHash)

                        const root = await this.getContentRoot<Flash>(syncRequest.branchId)
                        // @ts-ignore
                        const contentRootFlash = await this.flashStore.get(postSyncBranch.rootHash)
                        await root(contentRootFlash)

                        syncResponse = {
                            rootHash: syncRequest.rootHash,
                            commonHash: syncRequest.rootHash,
                            bookmark: postSyncBookmark,
                            flashlets: [],
                        }

                    } catch (e) {

                        this.logger.debug('[sank error]', e)

                        syncResponse = {
                            rootHash: syncRequest.syncState.commonHash,
                            commonHash: syncRequest.syncState.commonHash,
                            bookmark: preSyncBookmark,
                            flashlets: [],
                        }
                    }


                } else {
                    this.logger.debug(`[sank] ${syncRequest.repoId} === ${this.repoId}`)

                    syncResponse = {
                        rootHash: syncRequest.syncState.commonHash,
                        commonHash: syncRequest.syncState.commonHash,
                        bookmark: preSyncBookmark,
                        flashlets: [],
                    }
                }
            }
            // dumpReqRes(syncResponse, 'syncResponse')
            this.logger.debug('[sank end]')

            return syncResponse
        })
    }


    private async commitSync(syncContext: SyncContext): Promise<boolean> {

        this.logger.debug('[commit start]')
        syncContext.f_postSyncState = {...syncContext.d_advisedSyncState}

        if (syncContext.d_advisedSyncState === syncContext.a_preSyncState) {
            this.logger.debug('[commit end] ()')
            return false // no changes
        }

        const preCommitBranchHash = this.flashStore.getBranchRootHash(syncContext._branchId)
        const preCommitBranch: Branch = preCommitBranchHash === EmptyObjectHash
            ? makeBranchZero()
            : await this.flashStore.build<Branch>(preCommitBranchHash)

        syncContext.e_preCommitBranchHash = preCommitBranchHash
        syncContext.e_preCommitBranch0 = preCommitBranch
        syncContext.e_preCommitState = preCommitBranch.syncState?.[syncContext._remoteRepoId] ?? makeSyncStateZero()


        const hasIncomingDelta = syncContext.c_syncResponse.rootHash !== syncContext.c_syncResponse.commonHash
        const hadOutgoingDelta = syncContext.b_syncRequest.rootHash !== syncContext.b_syncRequest.syncState.commonHash
        const hasInterimDelta = syncContext.a_preSyncBranch0.rootHash !== syncContext.e_preCommitBranch0.rootHash

        if (hasIncomingDelta) {
            const latestBookmark = await this.flashStore.putSlice(syncContext.c_syncResponse.flashlets)
            if (!hasInterimDelta) {
                syncContext.f_postSyncState.thisBookmark = latestBookmark
            }
        }

        if (hasInterimDelta) {
            // Have had local changes since, so amend post sync state to represent the required merge
            syncContext.f_postSyncState.thisRootHash = syncContext.e_preCommitBranch0.rootHash
            syncContext.f_postSyncState.commonHash = syncContext.c_syncResponse.commonHash
        }

        const needsMerging = hasIncomingDelta && (hadOutgoingDelta || hasInterimDelta)

        if (needsMerging) {
            this.logger.debug(`[merge start]`)

            try {
                const mergedHash = await this.merge(
                    syncContext.f_postSyncState.thatRootHash,
                    syncContext.f_postSyncState.commonHash,
                    syncContext.f_postSyncState.thisRootHash,
                )
                syncContext.f_postSyncState.thisRootHash = mergedHash
                syncContext.f_postSyncState.commonHash = syncContext.c_syncResponse.rootHash
                syncContext.f_postSyncState.thatRootHash = syncContext.c_syncResponse.rootHash

                this.logger.debug(`[merge end] (contentRootHash: ${mergedHash}) --> ${this.repoId}`)

            } catch (e) {
                this.logger.debug(`[merge error] ${e}`)
                throw e
            }
        }


        syncContext.f_postSyncBranch0 = {
            rootHash: syncContext.f_postSyncState.thisRootHash,
            syncState: {
                ...preCommitBranch.syncState,
                [syncContext._remoteRepoId]: syncContext.f_postSyncState
            }
        }

        syncContext.f_postSyncBranchHash = await this.flashStore.put(syncContext.f_postSyncBranch0)


        let haveSynced = false

        try {
            haveSynced = await this.flashStore.pushBranchRootHash(syncContext._branchId, syncContext.f_postSyncBranchHash, preCommitBranchHash)
        } catch (e) {
            this.logger.debug('I think this is stale?!?', e)
            return false
        }

        if (haveSynced) {

            this.logger.debug(`[commit synced] (branchRootHash: ${syncContext.f_postSyncBranchHash}) --> ${this.repoId}`)
            const checkHash = this.flashStore.getBranchRootHash(syncContext._branchId)
            this.logger.debug(`[commit check] (branchRootHash: ${checkHash})`)

            this.branchRootHashCache.set(syncContext._branchId, syncContext.f_postSyncBranchHash)

            const branchRoot = await this.getContentRoot<Flash>(syncContext._branchId)
            const contentRootFlash = await this.flashStore.get(syncContext.f_postSyncBranch0.rootHash)

            this.logger.debug(`[commit end] (branchRootHash: ${syncContext.f_postSyncBranchHash}, contentRootHash: ${syncContext.f_postSyncBranch0.rootHash}) --> ${this.repoId}`)

            await branchRoot(contentRootFlash)

            return haveSynced

        } else {

            this.logger.debug('[commit end] ()')

            return false
        }
    }


    async reset() {
        this.branchRootHashCache.clear()
        this.contentRootNodes.clear()
        await this.flashStore.reset()
        this.syncing = false
    }


    async dispose() {
        this.branchRootHashCache.clear()
        this.contentRootNodes.clear()
        await this.flashStore.dispose()
    }
}


// eslint-disable-next-line no-unused-vars
function dumpContext(syncContext: SyncContext) {
    const copy: SyncContext = JSON.parse(JSON.stringify(syncContext))

    if (copy.b_syncRequest?.flashlets) {
        copy.b_syncRequest.flashlets = undefined
    }

    if (copy.c_syncResponse?.flashlets) {
        copy.c_syncResponse.flashlets = undefined
    }

    console.log('[sync context]', asDoc(sorted(copy)))
}


// eslint-disable-next-line no-unused-vars
function dumpReqRes(reqRes: SyncRequest | SyncResponse, type: 'syncRequest' | 'syncResponse') {
    const copy = JSON.parse(JSON.stringify(reqRes))
    copy.flashlets = undefined
    dump({[type]: copy})
}

function sorted(o: any) {
    return Object.keys(o)
        .sort()
        .reduce((accumulator, key) => {
            accumulator[key] = o[key]
            return accumulator
        }, {} as any)

}
