import {createSignal} from 'solid-js'
import {PasswordBox} from '../../../components/TextBox/PasswordBox'
import styles from './NewPassword.module.css'
import {TextBox} from '../../../components/TextBox/TextBox'
import {isValidEmail, isValidPassword} from '../../../to-abstract/validate'
import {Show} from 'solid-js/web'
import {useUserAdminService} from '../../../controllers/DashboardServiceController'
import {Spinner} from '../../../components/Spinner/Spinner'
import {NOOP} from '@peachy/utility-kit-pure'
import {Toggle} from '@peachy/client-kit'
import {NewPasswordRequest} from '@peachy/web-dashboard-pure'


type NewPasswordProps = {
    heading: string
    setPassword: (resetPasswordRequest: NewPasswordRequest) => Promise<void>
}

export function NewPassword(props: NewPasswordProps) {

    const userAdminService = useUserAdminService()

    const [email, setEmail] = createSignal('')
    const [password, setPassword] = createSignal('')

    const [isRandomPassword, setIsRandomPassword] = createSignal(true)

    const hasCredentials = () =>
        isValidEmail(email()) && (isRandomPassword() || isValidPassword(password()))

    const resetPassword = async () => {
        if (hasCredentials()) {
            props.setPassword({email: email(), password: isRandomPassword() ? null : password()})
                .catch(NOOP)
        }
    }

    return (
        <div class={styles.AdminResetPassword}>
            <h2>{props.heading}</h2>
            <fieldset>
                <TextBox value={email()} onChange={setEmail} placeholder={'email'}/>
                <Toggle isOn={isRandomPassword()} 
                        onToggle={setIsRandomPassword} 
                        label={'Set random password?'}
                        class={styles.toggle} />
                <Show when={!isRandomPassword()}>
                    <PasswordBox value={password()} onChange={setPassword} placeholder={'new password'}/>
                </Show>
                <button onClick={resetPassword} disabled={!hasCredentials()}>Reset</button>
                <Error/>
            </fieldset>
            <Spinner isOpen={userAdminService.isBusy()}/>
        </div>
    )
}


export function Error() {
    const userAdminService = useUserAdminService()

    return (
        <Show when={!!userAdminService.error()}>
            <mark>{userAdminService.error()}</mark>
        </Show>
    )

}
